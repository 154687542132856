import {
  CLEAR_STORE,
  SET_NAVBAR_TITLE,
  SET_PAGE_LOADING,
  SET_IS_DRAWER_OPEN,
} from "../action-types/commonActionTypes";

export const clearStore = () => {
  return {
    type: CLEAR_STORE,
  };
};

export const setPageLoading = (state) => {
  return {
    type: SET_PAGE_LOADING,
    payload: state,
  };
};

export const setNavbarTitle = (state) => {
  return {
    type: SET_NAVBAR_TITLE,
    payload: state,
  };
};

export const setIsDrawerOpen = (state) => {
  return {
    type: SET_IS_DRAWER_OPEN,
    payload: state,
  };
}
