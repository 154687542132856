import axiosInstance from "../../commons/helpers/axios";

const BillingService = {
  search: (limit, skip, search, accountType, timezone) => {
    return axiosInstance.get("/companies/billing-search", {
      params: {
        limit,
        skip,
        search,
        accountType,
        timezone,
      },
    });
  },
  searchByCompany: (companyId, limit, skip, search) => {
    return axiosInstance.get(`/payments/company/${companyId}/search`, {
      params: {
        limit,
        skip,
        search,
      },
    });
  },
  save: (data) => {
    return axiosInstance.post("/billings", data);
  },
  savePaymentByCompany: (companyId, body) => {
    return axiosInstance.post("/payments/company/" + companyId, body);
  },
  editBusinessPaymentById: (paymentId, body) => {
    return axiosInstance.put(`/payments/${paymentId}/business`, body);
  },
  edit: (id, data) => {
    return axiosInstance.put(`/billings/${id}`, data);
  },
  getById: (id) => {
    return axiosInstance.get(`/billings/${id}`);
  },
  getAdditionalPayments: (paymentId) => {
    return axiosInstance.get(`/payments/${paymentId}/additional`);
  },
  saveAdditionalPayment: (companyId, timezone, body) => {
    return axiosInstance.post(
      "/payments/company/" + companyId + "/additional",
      body, {
      params: {
        timezone,
      },
    }
    );
  },
  getSuggestedAmountsForAdditionalPayment: (companyId, cardsQuantity) => {
    return axiosInstance.get(`/payments/company/${companyId}/suggested-amount`, {
      params: {
        cardsQuantity
      }
    });
  }
};

export default BillingService;
