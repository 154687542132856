import { useState } from "react";

import Dropdown from "../../commons/components/Dropdown";

const options = [
  { key: "THIS_WEEK", value: "Esta semana" },
  { key: "THIS_MONTH", value: "Este mes" },
  { key: "THIS_YEAR", value: "Este año" },
];

const BaseFiltersSelector = ({ onChange }) => {
  const [loading, setLoading] = useState(false);
  const [initialOption, setInitialOption] = useState(options[0]);

  const handleClick = (option) => {
    onChange(option.key);
  };

  return (
    <Dropdown
      options={options}
      fullWidth={true}
      hasIcon={false}
      onClick={handleClick}
      loading={loading}
      defaultOption={initialOption}
      responsive={true}
    />
  );
};

export default BaseFiltersSelector;
