import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { Login } from "../auth/views/Login";
import { BillingDetail } from "../billings/views/BillingDetail";
import { BillingsList } from "../billings/views/BillingsList";
import Layout from "../commons/components/Layout";
import { NotFound } from "../commons/components/NotFound";
import { CompaniesList } from "../companies/views/CompaniesList";
import { EmployeesList } from "../employees/views/EmployeesList";
import { PricingsList } from "../pricings/views/PricingsList";
import { ACCOUNT_TYPES } from "../commons/constants/employee";
import { CompanyStatistics } from "../statistics/views/CompanyStatistics";
import { BusinessCardsList } from "../business-cards/views/BusinessCardsList";
import { AdditionalPaymentForm } from "../billings/views/AdditionalPaymentForm";
import { ConfigurationView } from "../admin-configuration/views/ConfigurationView";

function AppRoutes(props) {
  const isAuthenticated = !!useSelector((state) => state.auth.token);
  return (
    <Routes>
      <Route
        path='/login'
        element={!isAuthenticated ? <Login /> : <Navigate to='/' />}
      />
      <Route path='/' element={<Layout />}>
        <Route index element={<EmployeesList />} />
        <Route
          path='/employees'
          element={<EmployeesList accountType={ACCOUNT_TYPES.BUSINESS} />}
        />
        <Route
          path='/personal-employees'
          element={<EmployeesList accountType={ACCOUNT_TYPES.PERSONAL} />}
        />
        <Route path='/business-cards' element={<BusinessCardsList />} />
        <Route path='/companies' element={<CompaniesList />} />
        <Route
          path='/billings'
          element={<BillingsList accountType={ACCOUNT_TYPES.BUSINESS} />}
        />
        <Route
          path='/personal-billings'
          element={<BillingsList accountType={ACCOUNT_TYPES.PERSONAL} />}
        />
        <Route path='/billings/:companyId' element={<BillingDetail />} />
        <Route path='/precios' element={<PricingsList />} />
        <Route
          path='/additional-payments'
          element={<AdditionalPaymentForm />}
        />
        <Route path='/statistics/:companyId' element={<CompanyStatistics />} />
        <Route path='/configuration' element={<ConfigurationView />} />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
