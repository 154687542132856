import axiosInstance from "../../commons/helpers/axios";

const CompanyService = {
  getAll: (filters = {}) => {
    return axiosInstance.get("/companies", { params: filters });
  },
  search: (limit, skip, search) => {
    return axiosInstance.get("/companies/search", {
      params: {
        limit,
        skip,
        search,
      },
    });
  },
  save: (data) => {
    return axiosInstance.post("/companies", data);
  },
  edit: (id, data) => {
    return axiosInstance.put(`/companies/${id}`, data);
  },
  getById: (id) => {
    return axiosInstance.get(`/companies/${id}`);
  },
  getPlans: () => {
    return axiosInstance.get("/companies/plans");
  },
  delete: (id, comments, fileUrl = "") => {
    return axiosInstance.delete(`/companies/${id}`, {
      params: {
        comments,
        fileUrl,
      },
    });
  },
  restore: (id) => {
    return axiosInstance.post(`/companies/${id}/restore`);
  },
};

export default CompanyService;
