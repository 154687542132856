import {
  Alert,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNavbarTitle } from "../../store/common/actions/commonActions";
import BillingsTable from "../components/BillingsTable";
import BillingForm from "../components/BillingForm";
import BillingService from "../services/BillingService";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import FileServices from "../../commons/services/FileServices";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";

const CustomModal = styled(Modal)`
  width: 100%;
`;

export function BillingsList({ accountType }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [billingFormValue, setBillingFormValue] = useState({});
  const [voucherFile, setVoucherFile] = useState(null);
  const [voucherLoading, setVoucherLoading] = useState(false);
  const [billingRowSelected, setBillingRowSelected] = useState(null);
  const [isValidForm, setIsValidForm] = useState(false);
  const [reloadBillings, setReloadBillings] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBillingFormChange = (formValue) => {
    setBillingFormValue(formValue);
    setIsValidForm(validateForm(formValue));
  };

  const handleVoucherChange = (file) => {
    setVoucherFile(file);
  };

  useEffect(() => {
    const suffixTitle =
      accountType === ACCOUNT_TYPES.PERSONAL ? "Personal" : "Corporativa";
    dispatch(setNavbarTitle(`Tarjetas HolaLink - Facturación ${suffixTitle}`));
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [dispatch, isModalOpen, accountType]);

  const handleSave = async () => {
    if (!isValidForm) return;

    const billingRequest = { ...billingFormValue };

    if (voucherFile) {
      const voucher = await uploadVoucher(voucherFile);
      billingRequest.voucher = voucher;
    }

    if (billingFormValue.id) {
      BillingService.edit(billingFormValue.id, billingRequest)
        .then((response) => {
          setReloadBillings(true);
          setIsModalOpen(false);
          setModalErrorMessage("");
        })
        .catch(({ response }) => {
          const error = response.data;
          setModalErrorMessage(error ? error.message : "Ocurrió un error");
          setTimeout(() => {
            setModalErrorMessage("");
          }, 10000);
        });
    } else {
      BillingService.save(billingRequest)
        .then((response) => {
          setReloadBillings(true);
          setIsModalOpen(false);
          setModalErrorMessage("");
        })
        .catch(({ response }) => {
          const error = response.data;
          setModalErrorMessage(error ? error.message : "Ocurrió un error");
          setTimeout(() => {
            setModalErrorMessage("");
          }, 10000);
        });
    }
  };

  const uploadVoucher = async (file) => {
    if (!file) return null;

    setVoucherLoading(true);
    const filename = `${file.name}-${new Date().getTime()}`;
    const reference = FileServices.getReference("voucher", filename);
    let url = null;
    try {
      url = await FileServices.uploadFile(file, reference);
    } catch (error) {
      console.log(error);
    } finally {
      setVoucherLoading(false);
    }
    return url;
  };

  const handleOpenEditModal = (billingRow) => {
    setBillingRowSelected(billingRow);
    setModalErrorMessage("");
    setIsModalOpen(true);
  };

  const handleToToBillingDetails = (companyId) => {
    if (!companyId) return;
    navigate(`/billings/${companyId}`);
  };

  const validateForm = (formValue) => {
    const { company, amount, billingCycle, currency } = formValue;
    const hasEmptyValues = !company || !amount || !billingCycle || !currency;

    return !hasEmptyValues;
  };

  return (
    <>
      <BillingsTable
        openEditModal={handleOpenEditModal}
        goToBillingDetails={handleToToBillingDetails}
        reload={reloadBillings}
        accountType={accountType}
        setReloadBillings={setReloadBillings}
      />
      {isModalOpen && (
        <CustomModal setIsOpen={setIsModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsModalOpen}>Facturación</ModalHeader>
          <ModalContent>
            <BillingForm
              billingRow={billingRowSelected}
              onChange={handleBillingFormChange}
              onVoucherChange={handleVoucherChange}
              voucherLoading={voucherLoading}
            />
            {modalErrorMessage && (
              <Alert type="error">{modalErrorMessage}</Alert>
            )}
          </ModalContent>
          <ModalFooter>
            <Button
              variant={!isValidForm ? "disabled" : "primary"}
              size="small"
              onClick={handleSave}
            >
              Guardar
            </Button>
          </ModalFooter>
        </CustomModal>
      )}
    </>
  );
}
