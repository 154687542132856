import styled from "@emotion/styled";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  Table,
} from "@holalink/react-ui-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import BillingService from "../services/BillingService";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";
import { PaymentForm } from "./PaymentForm";
import { PaymentEditionForm } from "./PaymentEditionForm";
import theme from "../../commons/theme";
import { Add } from "@mui/icons-material";
import { AdditionalPaymentTable } from "./AdditionalPaymentTable";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 auto;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .ri-edit-box-line {
    color: ${theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-delete-bin-line {
    color: ${theme.colors.pastelRed};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-refresh-line {
    color: ${theme.colors.springGreenHover};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-line-chart-fill {
    color: ${theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
`;

const CompanyBillingsTable = ({ company, reload, setReloadBillings }) => {
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPaymentOpen, setPaymentIsOpen] = useState(false);
  const [isAdditionalPaymentOpen, setAdditionalPaymentIsOpen] = useState(false);
  const [additionalPayments, setAdditionalPayments] = useState([]);
  const [isEditionOpen, setIsEditionOpen] = useState(false);
  const [editionValues, setEditionValues] = useState(null);
  const [paymentId, setPaymentId] = useState("");
  const navigate = useNavigate();

  const isPersonal = useMemo(() => {
    return (
      !!company.companyType && company.companyType === ACCOUNT_TYPES.PERSONAL
    );
  }, [company]);

  const columns = useMemo(
    () => [
      {
        accessor: "counter",
        Header: "ID",
        width: "60",
        Cell: ({ value }) => {
          if (value && value > 0) {
            return <CounterStyled>#{value}</CounterStyled>;
          } else {
            return <CounterStyled>{"-"}</CounterStyled>;
          }
        },
      },
      {
        accessor: "_id",
        width: "1",
        Cell: ({ value }) => <></>,
      },

      {
        accessor: "createdAt",
        Header: `Fecha Facturación`,
        width: "200",
        Cell: ({ value }) => {
          return format(new Date(value), "dd/MM/yyyy");
        },
      },
      {
        accessor: "name",
        Header: "Plan",
        with: "200",
      },
      {
        id: "quantity",
        accessor: (row) => `${row.quantity || "-"}`,
        Header: `Cantidad Inicial`,
        width: "150",
      },
      {
        id: "fullQuantity",
        accessor: (row) => `${row.fullQuantity || "-"}`,
        Header: `Cantidad Actual`,
        width: "150",
      },
      {
        id: "amount",
        accessor: (row) =>
          `${row.currency} ${+row.price * +(row.quantity || "0")}`,
        Header: `Monto Pagado`,
        width: "150",
      },
      {
        accessor: "duration",
        Header: `Duración`,
        width: "150",
      },
      {
        id: "additionalDays",
        accessor: (row) => `${row.additionalDays || "0"}`,
        Header: "Días adicionales",
        width: "150",
      },
      {
        id: "status",
        Header: "Estado",
        with: "200",
        Cell: () => {
          return "Pagado";
        },
      },
      {
        id: "actions",
        accessor: (row) => ({
          id: row.id,
          disabled: row.disabled,
          additionalDays: row.additionalDays,
        }),
        Header: "Acción",
        width: "120",
        Cell: (cellProps) => {
          const values = cellProps.cell.row.values;
          return (
            !isPersonal && (
              <ActionContainer>
                <i
                  className='ri-edit-box-line'
                  onClick={() =>
                    openEditModal(values._id, values.additionalDays)
                  }
                ></i>
                <i
                  className='ri-eye-line'
                  onClick={() =>
                    openAdditionalPaymentListModal(values._id)
                  }
                ></i>
              </ActionContainer>
            )
          );
        },
      },
    ],
    [isPersonal, data]
  );

  // FORM
  const openPaymentForm = () => {
    setPaymentIsOpen(true);
  };
  const openEditModal = (paymentId, additionalDays) => {
    setPaymentId(paymentId);
    setEditionValues({ additionalDays });
    setIsEditionOpen(true);
  };

  const openAdditionalPaymentListModal = (paymentId) => {
    const currentPayment = data.find((payment) => payment._id === paymentId);
    if (!currentPayment) alert("No se encontró el pago");
    setAdditionalPayments(currentPayment.additionalPayments || [])
    setAdditionalPaymentIsOpen(true);
  };

  const loadBillings = ({ limit, skip, companyId }) => {
    setLoading(true);
    BillingService.searchByCompany(companyId, limit, skip)
      .then((response) => {
        setData(response.data.data);
        setPageCount(response.data.paging.pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadBillings({
        limit: pageSize,
        skip: pageSize * pageIndex,
        companyId: company.id,
      });
    },
    [company]
  );


  useEffect(() => {
    if (reload) {
      fetchData({ pageSize: 10, pageIndex: 0 });
      setReloadBillings(false);
    }
  }, [reload, fetchData, setReloadBillings]);

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          cursor: "pointer",
        }}
      >
        <i className='ri-arrow-left-line' />
        Volver
      </div>

      <Button variant='primary' size='small' onClick={openPaymentForm}>
        <span className='p-2'>Agregar pago</span>
      </Button>

      {isPaymentOpen && (
        <Modal setIsOpen={setPaymentIsOpen} size='medium'>
          <ModalHeader setIsOpen={setPaymentIsOpen}>Crear pago</ModalHeader>
          <ModalContent>
            <PaymentForm
              company={company}
              callFetchData={fetchData}
              closeModal={() => {
                setPaymentIsOpen(false);
              }}
              isPersonal={isPersonal}
            />
          </ModalContent>
        </Modal>
      )}
      {isEditionOpen && (
        <Modal setIsOpen={setIsEditionOpen} size='medium'>
          <ModalHeader setIsOpen={setIsEditionOpen}>Editar pago</ModalHeader>
          <ModalContent>
            <PaymentEditionForm
              values={editionValues}
              paymentId={paymentId}
              closeModal={() => {
                setIsEditionOpen(false);
              }}
              callFetchData={fetchData}
            />
          </ModalContent>
        </Modal>
      )}
      {isAdditionalPaymentOpen && (
        <Modal setIsOpen={setAdditionalPaymentIsOpen} size='medium'>
          <ModalHeader setIsOpen={setAdditionalPaymentIsOpen}>Lista de pagos adicionales</ModalHeader>
          <ModalContent>
            <AdditionalPaymentTable
              additionalPayments={additionalPayments}
              closeModal={() => setAdditionalPaymentIsOpen(false)}
            />
          </ModalContent>
        </Modal>
      )}
      <Table
        pageCount={pageCount}
        fetchData={fetchData}
        loading={loading}
        data={data}
        columns={columns}
        isPaginated
      />
    </>
  );
};

export default CompanyBillingsTable;
