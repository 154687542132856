import React, { useEffect, useState } from "react";
import BillingService from "../services/BillingService";
import { Button, Flex, ModalFooter } from "@holalink/react-ui-components";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../../commons/theme";

const ContentForm = styled.div`
  width: 100%;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${(props) => props.error !== "" && InputError}
`;

const OneColumnContainer = styled(Flex)`
  margin-bottom: 20px;
`;
const ErrorLabel = styled.span`
  color: ${theme.colors.pastelRed};
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
`;

const validations = {
  additionalDays: {
    validate: (additionalDays) => {
      if (!/^[0-9]+$/.test(additionalDays.toString())) {
        return "Solo ingresar números enteros positivos";
      }
      //TODO: Verificar si mantener la siguiente condición o solo mantener la anterior
      if (parseInt(additionalDays) < 0) {
        return "Debe ingresar una cantidad válida de días";
      }
      return "";
    },
  },
};

//TODO: Generalizar el formulario de creación y edición para no tener código repetido
export const PaymentEditionForm = ({
  values,
  paymentId,
  closeModal,
  callFetchData,
}) => {
  const [formValues, setFormValues] = useState(
    values || {
      additionalDays: 0,
    }
  );
  const [errors, setErrors] = useState({
    additionalDays: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    let valid = true;
    Object.keys(formValues).forEach((key) => {
      const error = validations[key].validate(formValues[key]);
      setErrors((prevState) => ({
        ...prevState,
        [key]: error,
      }));

      if (error !== "") {
        valid = false;
      }
    });
    return valid;
  };

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const editPayment = () => {
    const valid = validate();
    if (valid) {
      const { additionalDays } = formValues;
      if (paymentId) {
        BillingService.editBusinessPaymentById(paymentId, {
          additionalDays,
        })
          .then((payment) => {
            closeModal();
            callFetchData({ pageSize: 10, pageIndex: 0 });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <Flex flexDirection='column'>
      <OneColumnContainer>
        <ContentForm>
          <label>Días adicionales*</label>
          <InputForm
            name='additionalDays'
            type='number'
            placeholder='Días adicionales'
            defaultValue={formValues.additionalDays}
            onChange={handleChange}
            error={errors?.additionalDays}
          />
          {errors?.additionalDays && (
            <ErrorLabel>{errors?.additionalDays}</ErrorLabel>
          )}
        </ContentForm>
      </OneColumnContainer>
      <ModalFooter>
        <Button variant='primary' size='small' onClick={editPayment}>
          Guardar
        </Button>
      </ModalFooter>
    </Flex>
  );
};
