import React, { useEffect, useState } from "react";

import {
  Button,
  Flex,
  ModalFooter,
  Typography,
} from "@holalink/react-ui-components";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import DefaultSelect from "../../commons/components/DefaultSelect";
import PricingService from "../../pricings/services/PricingService";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";
import theme from "../../commons/theme";
import BillingService from "../services/BillingService";

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${(props) => props.error !== "" && InputError}
`;

const OneColumnContainer = styled(Flex)`
  margin-bottom: 20px;
`;

const TwoColumnContainer = styled(Flex)`
  gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
  > div {
    flex: 50%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContentForm = styled.div`
  width: 100%;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const ErrorLabel = styled.span`
  color: ${theme.colors.pastelRed};
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
`;

const validations = {
  pricing: {
    validate: (pricing) => {
      if (!pricing || pricing === "") {
        return "Debe seleccionar un precio";
      }
      return "";
    },
  },
  additionalDays: {
    validate: (additionalDays) => {
      if (!/^[0-9]+$/.test(additionalDays.toString())) {
        return "Solo ingresar números enteros positivos";
      }
      //TODO: Verificar si mantener la siguiente condición o solo mantener la anterior
      if (parseInt(additionalDays) < 0) {
        return "Debe ingresar una cantidad válida de días";
      }
      return "";
    },
  },
  cardsQuantity: {
    validate: (cardsQuantity) => {
      if (!/^[0-9]+$/.test(cardsQuantity.toString())) {
        return "Solo ingresar números enteros positivos";
      }
      if (!cardsQuantity || cardsQuantity <= 0) {
        return "Debe ingresar una cantidad válida de tarjetas";
      }
      return "";
    },
  },
};

export const PaymentForm = ({
  company,
  callFetchData,
  closeModal,
  isPersonal,
}) => {
  const [pricings, setPricings] = useState([]);
  const [currentPricing, setCurrentPricing] = useState({});
  const [loadingPricings, setLoadingPricings] = useState(false);

  const [formValues, setFormValues] = useState({
    pricing: "",
    additionalDays: 0,
    cardsQuantity: 0,
  });
  const [errors, setErrors] = useState({
    pricing: "",
    additionalDays: "",
    cardsQuantity: "",
  });



  const pricingOptions = pricings.map((pricing) => {
    return {
      label: `${pricing.name} - ${pricing.currency} ${pricing.price}`,
      value: pricing.id,
    };
  });

  const onChangePricing = (e) => {
    const { name, value } = e.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setCurrentPricing(pricings.find((pricing) => pricing.id === value));
  };

  const getCurrentPrice = () => {
    const { cardsQuantity } = formValues;
    if (currentPricing && cardsQuantity !== 0) {
      return `${currentPricing.price * cardsQuantity}`;
    }

    return 0;
  };

  // HANDLE CHANGE AND VALIDAE
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    let valid = true;

    if (isPersonal) {
      const key = "pricing";
      const error = validations[key].validate(formValues[key]);
      setErrors((prevState) => ({
        ...prevState,
        [key]: error,
      }));

      if (error !== "") {
        valid = false;
      }
    } else {
      Object.keys(formValues).forEach((key) => {
        const error = validations[key].validate(formValues[key]);
        setErrors((prevState) => ({
          ...prevState,
          [key]: error,
        }));

        if (error !== "") {
          valid = false;
        }
      });
    }
    return valid;
  };

  const savePayment = () => {
    const valid = validate();
    if (valid && company && company.id) {
      const { pricing, additionalDays, cardsQuantity } = formValues;
      BillingService.savePaymentByCompany(company.id, {
        pricingId: pricing,
        additionalDays,
        cardsQuantity,
        accountType: isPersonal
          ? ACCOUNT_TYPES.PERSONAL
          : ACCOUNT_TYPES.BUSINESS,
      })
        .then((payment) => {
          closeModal();
          callFetchData({ pageSize: 10, pageIndex: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const getPricings = () => {
      setLoadingPricings(true);

      PricingService.getPricingsByProduct(
        isPersonal ? ACCOUNT_TYPES.PERSONAL : ACCOUNT_TYPES.BUSINESS
      )
        .then((response) => {
          setPricings(response.data);
        })
        .finally(() => {
          setLoadingPricings(false);
        });
    };
    getPricings();
  }, []);

  return (
    <>
      <Flex flexDirection='column'>
        {/* Desplegable pricings, por defecto el pricing anual  */}
        <OneColumnContainer>
          <ContentForm>
            <label>Precio*</label>
            {loadingPricings ? (
              <span>Cargando...</span>
            ) : (
              <DefaultSelect
                name='pricing'
                defaultValue={formValues.pricing}
                placeholder='Seleccionar un Pricing'
                onChange={onChangePricing}
                options={pricingOptions}
                error={errors?.pricing}
              />
            )}
            {errors?.pricing && <ErrorLabel>{errors?.pricing}</ErrorLabel>}
          </ContentForm>
        </OneColumnContainer>
        {/* Input de cantidad de tarjetas */}
        {!isPersonal && (
          <TwoColumnContainer>
            <ContentForm>
              <label>Cantidad de tarjetas*</label>
              <InputForm
                name='cardsQuantity'
                type='number'
                placeholder='Cantidad de tarjetas'
                defaultValue={formValues.cardsQuantity}
                onChange={handleChange}
                error={errors?.cardsQuantity}
              />
              {errors?.cardsQuantity && (
                <ErrorLabel>{errors?.cardsQuantity}</ErrorLabel>
              )}
            </ContentForm>

            <ContentForm>
              <label>Días adicionales*</label>
              <InputForm
                name='additionalDays'
                type='number'
                placeholder='Días adicionales'
                defaultValue={formValues.additionalDays}
                onChange={handleChange}
                error={errors?.additionalDays}
              />
              {errors?.additionalDays && (
                <ErrorLabel>{errors?.additionalDays}</ErrorLabel>
              )}
            </ContentForm>
          </TwoColumnContainer>
        )}
        {formValues.cardsQuantity !== 0 && formValues.pricing !== "" && (
          <Typography size='small'>
            Precio a pagar: {getCurrentPrice()}
          </Typography>
        )}

        <ModalFooter>
          <Button variant='primary' size='small' onClick={savePayment}>
            Guardar
          </Button>
        </ModalFooter>
      </Flex>
    </>
  );
};
