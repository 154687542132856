import axiosInstance from "../../commons/helpers/axios";

const PricingService = {
  getAll: () => {
    return axiosInstance.get("/pricings");
  },
  search: (limit, skip, search) => {
    return axiosInstance.get("/pricings/search", {
      params: {
        limit,
        skip,
        search,
      },
    });
  },
  getPricingsByProduct: (productName) => {
    return axiosInstance.get(`/pricings/product?productName=${productName}`)
  },
  getProducts: () => {
    return axiosInstance.get("/pricings/products");
  },
  save: (data) => {
    return axiosInstance.post("/pricings", data);
  },
  edit: (id, data) => {
    return axiosInstance.put(`/pricings/${id}`, data);
  },
  getById: (id) => {
    return axiosInstance.get(`/pricings/${id}`);
  },
  delete: (id) => {
    return axiosInstance.delete(`/pricings/${id}`);
  },
};

export default PricingService;
