import {
  Alert,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNavbarTitle } from "../../store/common/actions/commonActions";
import EmployeeForm from "../components/EmployeeForm";
import EmployeesTable from "../components/EmployeesTable";
import EmployeeService from "../services/EmployeeService";
import styled from "@emotion/styled";
import theme from "../../commons/theme";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";

const TextDescription = styled.p`
  font-size: 1rem;
  color: #3d3d3d;
  margin-bottom: 20px;
  text-align: justify;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const TextInformation = styled.p`
  font-size: 1rem;
  color: ${theme.colors.blackOlive};
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export function EmployeesList({ accountType }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [employeeFormValue, setEmployeeFormValue] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [reloadEmployees, setReloadEmployees] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleEmployeeFormChange = (formValue, errors) => {
    setEmployeeFormValue(formValue);
    setIsValidForm(validateForm(formValue, errors));
  };

  useEffect(() => {
    const suffixTitle =
      accountType === ACCOUNT_TYPES.PERSONAL ? "Personales" : "Corporativos";
    dispatch(setNavbarTitle(`Tarjetas HolaLink - Usuarios ${suffixTitle}`));
    //prevent scroll on modal open
    if (isAddModalOpen || isEditModalOpen || isDeleteModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [
    dispatch,
    isAddModalOpen,
    isEditModalOpen,
    isDeleteModalOpen,
    accountType,
  ]);

  const handleSave = () => {
    if (!isValidForm) return;
    EmployeeService.save(employeeFormValue)
      .then((response) => {
        setReloadEmployees(true);
        setModalErrorMessage("");
        setIsAddModalOpen(false);
      })
      .catch((err) => {
        const message =
          err && err.response && err.response.data && err.response.data.message;
        setModalErrorMessage(message || "Ocurrió un error");
      });
  };

  const handleOpenAddModal = () => {
    setModalErrorMessage("");
    setIsAddModalOpen(true);
  };

  const handleEdit = () => {
    if (!isValidForm || !employeeToEdit) return;
    EmployeeService.edit(employeeToEdit.id, employeeFormValue)
      .then((response) => {
        setReloadEmployees(true);
        setModalErrorMessage("");
        setEmployeeToEdit(null);
        setIsEditModalOpen(false);
      })
      .catch((err) => {
        const message =
          err && err.response && err.response.data && err.response.data.message;
        setModalErrorMessage(message || "Ocurrió un error");
      });
  };

  const handleOpenEditModal = (employeeId) => {
    setModalErrorMessage("");
    if (!employeeId) return;
    EmployeeService.getById(employeeId)
      .then((response) => {
        setEmployeeToEdit(response.data);
        setIsEditModalOpen(true);
      })
      .catch((err) => {
        setEmployeeToEdit(null);
        setIsEditModalOpen(false);
      });
  };

  const handleDelete = () => {
    if (!employeeToDelete) return;
    if (accountType === ACCOUNT_TYPES.PERSONAL) {
      EmployeeService.deletePersonalAccount(employeeToDelete.id)
        .then((response) => {
          setReloadEmployees(true);
        })
        .finally(() => {
          setIsDeleteModalOpen(false);
          setEmployeeToDelete(null);
        });
    } else {
      EmployeeService.delete(employeeToDelete.id)
        .then((response) => {
          setReloadEmployees(true);
        })
        .finally(() => {
          setIsDeleteModalOpen(false);
          setEmployeeToDelete(null);
        });
    }
  };

  const handleOpenDeleteModal = (employeeId) => {
    if (!employeeId) return;
    EmployeeService.getById(employeeId)
      .then((response) => {
        setEmployeeToDelete(response.data);
        setIsDeleteModalOpen(true);
      })
      .catch((err) => {
        setIsDeleteModalOpen(false);
        setEmployeeToDelete(null);
      });
  };

  const validateForm = (formValue, errors) => {
    const { name, lastname, phone, email, company, role } = formValue;
    const hasEmptyValues =
      !name || !lastname || !phone || !email || !company || !role;
    const hasError = Object.values(errors).some((value) => !!value);
    if (hasEmptyValues || hasError) {
      return false;
    }
    return true;
  };

  return (
    <>
      <EmployeesTable
        setIsAddOpen={handleOpenAddModal}
        openEditModal={handleOpenEditModal}
        openDeleteModal={handleOpenDeleteModal}
        accountType={accountType}
        reload={reloadEmployees}
        setReloadEmployees={setReloadEmployees}
      />
      {isAddModalOpen && (
        <Modal setIsOpen={setIsAddModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsAddModalOpen}>Crear Usuario</ModalHeader>
          <ModalContent>
            <TextDescription>
              Ingresa la dirección de correo electrónico de las personas para
              invitarlas a unirse a tu negocio. Luego asígnales el acceso que
              tendrán, automáticamente enviaremos un email a cada uno de los
              correos agregados con un link de acceso.
            </TextDescription>
            <EmployeeForm
              onChange={handleEmployeeFormChange}
              accountType={accountType}
            />
            {modalErrorMessage && (
              <Alert type="error">{modalErrorMessage}</Alert>
            )}
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Button
                variant={!isValidForm ? "disabled" : "primary"}
                size="small"
                onClick={handleSave}
              >
                Crear
              </Button>
            </div>
          </ModalContent>
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal setIsOpen={setIsEditModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsEditModalOpen}>
            Editar Usuario
          </ModalHeader>
          <ModalContent>
            <EmployeeForm
              data={employeeToEdit}
              onChange={handleEmployeeFormChange}
              accountType={accountType}
            />
            {modalErrorMessage && (
              <Alert type="error">{modalErrorMessage}</Alert>
            )}
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Button
                variant={!isValidForm ? "disabled" : "primary"}
                size="small"
                onClick={handleEdit}
              >
                Editar
              </Button>
            </div>
          </ModalContent>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal setIsOpen={setIsDeleteModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsDeleteModalOpen}>
            Eliminar Usuario
          </ModalHeader>
          <ModalContent>
            <TextInformation>
              ¿Estás seguro que deseas eliminar al usuario{" "}
              {employeeToDelete?.name} {employeeToDelete?.lastname}?
            </TextInformation>
          </ModalContent>
          <ModalFooter>
            <Button
              variant="primary"
              size="small"
              onClick={handleDelete}
              style={{ marginRight: "10px" }}
            >
              Eliminar
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
