export const icons = {
  facebook: "bi-facebook",
  twitter: "bi-twitter",
  instagram: "bi-instagram",
  linkedin: "bi-linkedin",
  twitch: "bi-twitch",
  github: "bi-github",
  behance: "bi-behance",
  snapchat: "bi-snapchat",
  youtube: "bi-youtube",
  vimeo: "bi-vimeo",
  tiktok: "bi-tiktok",
  whatsapp: "bi-whatsapp",
  telegram: "bi-telegram",
  wechat: "bi-wechat",
  signal: "bi-signal",
  paypal: "bi-paypal",
  phones: "bi-telephone-fill",
  email: "bi-envelope-fill",
  pdf: "bi-file-earmark-pdf-fill",
  address: "bi-geo-alt-fill",
  webpage: "bi-globe",
  file: "",
  addcontact: "bi-person-fill-add",
};
