import axiosInstance from "../../commons/helpers/axios";

const PlanService = {
  getAll: () => {
    return axiosInstance.get("/plans");
  },
  search: (limit, skip, search) => {
    return axiosInstance.get("/plans/search", {
      params: {
        limit,
        skip,
        search,
      },
    });
  },
  save: (data) => {
    return axiosInstance.post("/plans", data);
  },
  edit: (id, data) => {
    return axiosInstance.put(`/plans/${id}`, data);
  },
  getById: (id) => {
    return axiosInstance.get(`/plans/${id}`);
  },
  delete: (id) => {
    return axiosInstance.delete(`/plans/${id}`);
  },
};

export default PlanService;
