import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootSaga from "./rootSaga";
import authReducer from "./auth/reducers/authReducer";
import commonReducer from "./common/reducers/commonReducer";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    common: commonReducer,
  })
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

export const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
