import { Flex } from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import theme from "../../commons/theme";
import { css } from "@emotion/react";
import DefaultSelect from "../../commons/components/DefaultSelect";
import ReactSwitch from "react-switch";

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const InputContainer = styled(Flex)`
  margin-bottom: 20px;
`;

const ContentForm = styled.div`
  width: 100%;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${({ error }) => error && InputError}
`;

const ErrorLabel = styled.span`
  font-size: 0.8rem;
  color: ${theme.colors.pastelRed};
  margin-top: 5px;
  display: block;
`;

const validations = {
  name: {
    validate: (name) => {
      if (!name) {
        return "El nombre es requerido";
      }
      if (name.trim() === "" || !/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(name)) {
        return "El nombre debe contener letras";
      } else if (name.length < 3) {
        return "El nombre debe tener al menos 3 caracteres";
      }
      return "";
    },
  },
  price: {
    validate: (price) => {
      if (!price) {
        return "El precio es requerido";
      }
      if (price < 0) {
        return "El precio debe ser mayor o igual a 0";
      }
      return "";
    },
  },
  duration: {
    validate: (duration) => {
      if (!duration) {
        return "La duración es requerida";
      }
      if (duration < 0) {
        return "La duración no es válida";
      }
      return "";
    },
  },
  currency: {
    validate: (currency) => {
      if (!currency) {
        return "La moneda es requerida";
      }
      return "";
    },
  },
  trial: {
    validate: (_trial) => {
      return "";
    },
  },
  level: {
    validate: (level) => {
      if (!level) {
        return "El nivel es requerido";
      }
      return "";
    },
  },
  product: {
    validate: (product) => {
      if (!product) {
        return "El producto es requerido";
      }
      return "";
    },
  },
  active: {
    validate: (_active) => {
      return "";
    },
  },
};

const PricingForm = ({ data, products, onChange }) => {
  const [formValue, setFormValue] = useState(
    data || {
      level: "unique",
      active: true,
    }
  );
  const [errors, setErrors] = useState({
    name: "",
    price: "",
    duration: "",
    currency: "",
    trial: "",
    level: "",
    product: "",
    active: "",
  });

  const currencies = [
    {
      value: "PEN",
      label: "Soles S/",
    },
    {
      value: "USD",
      label: "Dólares $",
    },
  ];

  const validateField = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: validations[name].validate(value),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateField(name, value);
  };

  const handleSwitchChange = (name, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateField(name, value);
  };

  useEffect(() => {
    if (!data) return;
    setData(data);
  }, [data]);

  useEffect(() => {
    onChange(formValue);
  }, [formValue, onChange]);

  const setData = (initialFormData) => {
    setFormValue({
      name: initialFormData.name,
      price: initialFormData.price,
      duration: initialFormData.duration,
      currency: initialFormData.currency,
      trial: initialFormData.trial,
      level: initialFormData.level,
      product: initialFormData.product,
      active: initialFormData.active,
    });
  };

  return (
    <Flex flexDirection="column" p="3">
      <InputContainer>
        <ContentForm>
          <label>Nombre*</label>
          <InputForm
            type="text"
            name="name"
            placeholder="Nombre"
            value={formValue.name}
            onChange={handleChange}
            error={errors?.name}
          />
          {errors?.name && <ErrorLabel>{errors?.name}</ErrorLabel>}
        </ContentForm>
      </InputContainer>
      <InputContainer>
        <ContentForm>
          <label htmlFor="pricing-trial-control">Es Trial</label>
          <ReactSwitch
            checked={formValue.trial || false}
            onChange={(value) => handleSwitchChange("trial", value)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="pricing-trial-control"
          />
        </ContentForm>
      </InputContainer>
      <InputContainer>
        <ContentForm>
          <label>Precio*</label>
          <InputForm
            type="number"
            name="price"
            placeholder="Precio"
            value={formValue.price}
            onChange={handleChange}
            error={errors?.price}
          />
          {errors?.price && <ErrorLabel>{errors?.price}</ErrorLabel>}
        </ContentForm>
      </InputContainer>
      <InputContainer>
        <ContentForm>
          <label>Moneda*</label>
          <DefaultSelect
            name="currency"
            placeholder="Seleccionar moneda"
            options={currencies}
            className="select-search"
            value={formValue.currency}
            onChange={handleChange}
            error={errors?.currency}
          />
          {errors?.currency && <ErrorLabel>{errors.currency}</ErrorLabel>}
        </ContentForm>
      </InputContainer>
      <InputContainer>
        <ContentForm>
          <label>Duración*</label>
          <InputForm
            type="number"
            name="duration"
            placeholder="Duración"
            value={formValue.duration}
            onChange={handleChange}
            error={errors?.duration}
          />
          {errors?.duration && <ErrorLabel>{errors?.duration}</ErrorLabel>}
        </ContentForm>
      </InputContainer>
      <InputContainer>
        <ContentForm>
          <label>Producto*</label>
          <DefaultSelect
            name="product"
            placeholder="Seleccionar producto"
            options={products}
            className="select-search"
            value={formValue.product}
            onChange={handleChange}
            error={errors?.product}
          />
          {errors?.product && <ErrorLabel>{errors.product}</ErrorLabel>}
        </ContentForm>
      </InputContainer>
      <InputContainer>
        <ContentForm>
          <label htmlFor="pricing-active-control">Activo</label>
          <ReactSwitch
            checked={formValue.active || false}
            onChange={(value) => handleSwitchChange("active", value)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="pricing-active-control"
          />
        </ContentForm>
      </InputContainer>
    </Flex>
  );
};

export default PricingForm;
