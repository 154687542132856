import axiosInstance from "../../commons/helpers/axios";

const MetadataService = {
    getMetadata: () => {
        return axiosInstance.get("/metadata");
    },
    save: (data) => {
        return axiosInstance.post("/metadata", data);
    },
    edit: (data) => {
        return axiosInstance.put(`/metadata`, data);
    },
};

export default MetadataService;