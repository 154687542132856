import { useState } from "react";

export const useForm = ({ initialFormValues, initialErrors, validations = undefined }) => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialErrors);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFile = (e) => {
    const name = e.target.name;
    const file = e.currentTarget.files[0];
    setFormValues((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };

  const resetForm = () => {
    setFormValues((prevState) => ({
      ...prevState,
      ...initialFormValues,
    }));
  };
  const validate = () => {
    let valid = true
    Object.keys(formValues).forEach((key) => {
      const error = validations[key].validate(formValues[key]);
      setErrors((prevState) => ({
        ...prevState,
        [key]: error,
      }));

      if (error !== "") {
        valid = false;
      }
    });
    return valid;
  }

  return {
    formValues,
    setFormValues,
    errors,
    setErrors,
    handleChange,
    handleFile,
    resetForm,
    validate
  };
};
