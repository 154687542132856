import styled from "@emotion/styled";
import { Typography } from "@holalink/react-ui-components";
import UserDropdown from "./UserDropdown";

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 1.5rem;
  align-items: center;
  height: 60px;
  background: white;
  .menu-icon {
    cursor: pointer;
    font-size: 20px;
  }
`;

const MenuIcon = styled.i`
  @media (min-width: 769px) {
    display: none;
  }
`;

const TypographyStyled = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  font-style: italic;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const TopNavBar = ({ user, onLogout, onToggleSidebar, title }) => {
  const logout = () => {
    onLogout();
  };

  const toggleSidebar = () => {
    onToggleSidebar(true);
  };

  return (
    <NavBarContainer>
      <div className="d-flex gap-2 align-items-center">
        <MenuIcon
          className="ri-menu-line menu-icon"
          onClick={toggleSidebar}
        ></MenuIcon>
        <TypographyStyled variant="h5" weight="bold">
          {title}
        </TypographyStyled>
      </div>
      <UserDropdown name={user} logout={logout} />
    </NavBarContainer>
  );
};
