import { Button, Input, Link, Typography } from "@holalink/react-ui-components";
import { useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { EMAIL_REGEX } from "../../commons/constants/email";
import AuthService from "../services/AuthService";

const ForgotPasswordForm = ({
  onBackClick,
  onLoading,
  onErrorMessage,
  onSuccessMessage,
}) => {
  const [forgotForm, setForgotForm] = useState({ email: "" });
  const [forgotErrors, setForgotErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForgotForm((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s/g, ""),
    }));
  };

  const handleForgotFormValidation = () => {
    const errors = {};
    let isValid = true;

    if (!forgotForm.email) {
      errors.email = "Ingrese un correo";
      isValid = false;
    } else if (!EMAIL_REGEX.test(forgotForm.email)) {
      errors.email = "Ingrese un correo válido";
      isValid = false;
    }

    setForgotErrors(errors);

    return isValid;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    onErrorMessage("");
    onSuccessMessage("");
    const isValid = handleForgotFormValidation();
    if (!isValid) return;
    onLoading(true);
    AuthService.resetPassword(forgotForm)
      .then((response) => {
        onSuccessMessage("El envío se realizó satisfactoriamente");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          onErrorMessage(error.response.data.message);
        } else {
          onErrorMessage("Ocurrió un error al resetear su contraseña.");
        }
      })
      .finally(() => {
        onLoading(false);
      });
  };
  return (
    <form method="post" onSubmit={handleResetPassword}>
      <Link
        onClick={onBackClick}
        fullWidth={false}
        size="medium"
        variant="primary"
      >
        {" "}
        <MdOutlineArrowBack /> Volver
      </Link>
      <div id="forgot_pw" className="mt-3">
        <div className="form-group">
          <Input
            fullWidth
            name="email"
            type="text"
            label="Correo"
            error={!!forgotErrors.email}
            errorMessage={forgotErrors.email}
            onChange={handleChange}
          />
        </div>
        <Typography variant="tiny">
          Si la información ingresada es válida recibirás un correo con el link
          que te permitirá restablecer tu contraseña.
        </Typography>
        <div className="mt-5">
          <Button
            variant="primary"
            size="medium"
            fullWidth={true}
            type="submit"
          >
            Enviar correo
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
