import axios from "axios";
import { store } from "../../store";
import { clearStore } from "../../store/common/actions/commonActions";

const baseURL = `${process.env.REACT_APP_API_DEV_BASE_URL}/api`;

const getToken = () => {
  const state = store.getState();
  return state.auth.token;
};

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();

    config.headers = {
      Authorization: token ? `Bearer ${token}` : "",
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(clearStore());
      if (!window.location.href.includes("/login")) {
        window.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
