import axiosInstance from "../../commons/helpers/axios";

const EmployeeService = {
  search: (limit, skip, search, accountType) => {
    return axiosInstance.get("/employees/search", {
      params: {
        limit,
        skip,
        search,
        accountType,
      },
    });
  },
  save: (data) => {
    return axiosInstance.post("/employees", data);
  },
  edit: (id, data) => {
    return axiosInstance.put(`/employees/${id}`, data);
  },
  getById: (id) => {
    return axiosInstance.get(`/employees/${id}`);
  },
  delete: (id) => {
    return axiosInstance.delete(`/employees/${id}`);
  },
  deletePersonalAccount: (id) => {
    return axiosInstance.delete(`/employees/personal/${id}`)
  } 

};

export default EmployeeService;
