import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import RoundImageCropper from "./RoundImageCropper";

const PreviewContainer = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: end;
  cursor: pointer;
`;
const Image = styled.div`
  border: 1px solid #d7d7d7;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: url(${(props) => props.previewUrl});
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: 1;
`;

const AddIconButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f26464;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

export const LogoUploadInput = ({
  onHandleImage,
  currentLogoUrl,
  formType,
}) => {
  /*currentLogoUrl */

  const [previewUrl, setPreviewUrl] = useState(currentLogoUrl);
  const [image, setImage] = useState(undefined);
  const [openCropper, setOpenCropper] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!image) {
      setImage(undefined);
      if (currentLogoUrl) {
        setPreviewUrl(currentLogoUrl);
        return;
      }

      switch (formType) {
        case "user":
          setPreviewUrl(
            //require("../assets/img/account-circle.svg").default
            require("../assets/img/no-avatar-img.jpg")
          );
          break;
        case "company":
          setPreviewUrl(
            require("../assets/img/company-placeholder.svg").default
          );
          break;
        default:
          break;
      }
      //setPreviewUrl("../../assets/img/image-placeholder.png");
    }
  }, [image, currentLogoUrl, formType, setImage, setPreviewUrl]);

  const openImageExplorer = () => {
    document.getElementById("image-selector").click();
  };

  const selectImage = (e) => {
    let files = e.target.files;
    if (!files || files.length === 0) {
      setImage(undefined);
      setPreviewUrl(currentLogoUrl);
      return;
    }

    const image = files[0];
    setImage(image);
    let imageUrl = URL.createObjectURL(image);
    setPreviewUrl(imageUrl);
    //onHandleImage(image);
    setOpenCropper(true);
    return () => URL.revokeObjectURL(imageUrl);
  };

  const handleCroppedImage = async (image, url) => {
    setImage(image);
    setPreviewUrl(url);
    setLoading(true);
    await onHandleImage(image);
    setLoading(false);
    onConfirm();
  };

  const clearFile = (e) => {
    e.target.value = null;
  };

  const onCancel = () => {
    setImage(undefined);
    setPreviewUrl(currentLogoUrl);
    setOpenCropper(false);
  };

  const onConfirm = () => {
    setOpenCropper(false);
  };

  return (
    <div>
      <PreviewContainer onClick={openImageExplorer}>
        <Image previewUrl={previewUrl} />
        <AddIconButton>
          <i className="ri-add-line" />
        </AddIconButton>
      </PreviewContainer>
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        id="image-selector"
        onChange={selectImage}
        onClick={clearFile}
      />
      <RoundImageCropper
        imageSrc={previewUrl}
        openCropper={openCropper}
        setCroppedImage={handleCroppedImage}
        closeCropper={onCancel}
        cropType="round"
        loading={loading}
        withLoading={true}
      />
    </div>
  );
};

LogoUploadInput.propTypes = {
  formType: PropTypes.oneOf(["user", "company"]),
};
