import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setNavbarTitle } from "../../store/common/actions/commonActions";
import BusinessCardTable from "../components/BusinessCardTable";
import BusinessCardService from "../services/BusinessCardService";
import { downloadResponseBase64File } from "../../commons/helpers/files.utils";

export function BusinessCardsList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavbarTitle("Tarjetas HolaLink - Tarjetas"));
  }, [dispatch]);

  const onDownloadReport = (search) => {
    BusinessCardService.downloadReport(search).then(
      (response) => {
        downloadResponseBase64File(response);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <>
      <BusinessCardTable onDownloadReport={onDownloadReport} />
    </>
  );
}
