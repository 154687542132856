import { useState } from "react";
import styled from "@emotion/styled";
import { Alert, Typography } from "@holalink/react-ui-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ReactComponent as Logo } from "./../assets/img/logo-login.svg";
import { setAuthUser } from "../../store/auth/actions/authActions";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import LoginForm from "../components/LoginForm";
import { setPageLoading } from "../../store/common/actions/commonActions";
import theme from "../../commons/theme";

const RowStyled = styled.div`
  margin: 0px;
  min-height: 100vh !important;
  height: 100vh;
`;

const ImageContainer = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ededed;
  background-image: url(/greetings.jpg);
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  min-height: 100%;
  padding: 0 90px;
  color: #fff;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
  mix-blend-mode: multiply;
  background-color: rgba(0, 122, 255, 0.8);
  @media (max-width: 991px) {
    height: auto;
    padding: 95px 30px 60px 30px;
  }
  @media (max-width: 767px) {
    padding: 95px 15px 60px 15px;
  }
`;

export function Login() {
  const dispatch = useDispatch();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleLoading = (loading) => {
    dispatch(setPageLoading(loading));
  };

  const handleForgotPassword = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setForgotPassword(true);
  };

  const handleBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setForgotPassword(false);
  };

  const onSuccessLogin = (user, token) => {
    dispatch(setAuthUser({ user, token }));
    navigate("/employees");
  };

  return (
    <>
      <div className="container-fluid p-0" style={{ height: "100%" }}>
        <RowStyled className="row">
          <ImageContainer className="col-lg-6 p-0">
            <LeftWrapper></LeftWrapper>
          </ImageContainer>
          <div
            className="col-lg-6 d-flex flex-column content-right"
            style={{ backgroundColor: theme.colors.whiteLilac }}
          >
            <div className="container my-auto py-5">
              <div className="row">
                <div className="col-lg-9 col-xl-7 mx-auto">
                  <div className="text-center">
                    <Logo width="218px" />
                  </div>
                  {errorMessage && <Alert type={"error"}>{errorMessage}</Alert>}
                  {successMessage && (
                    <Alert type={"success"}>{successMessage}</Alert>
                  )}
                  {!forgotPassword && (
                    <LoginForm
                      onForgotPasswordClick={handleForgotPassword}
                      onLoading={handleLoading}
                      onErrorMessage={setErrorMessage}
                      onSuccessMessage={setSuccessMessage}
                      onSuccess={onSuccessLogin}
                    />
                  )}
                  {forgotPassword && (
                    <ForgotPasswordForm
                      onBackClick={handleBack}
                      onLoading={handleLoading}
                      onErrorMessage={setErrorMessage}
                      onSuccessMessage={setSuccessMessage}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="container pb-3 copy text-center">
              <Typography variant="tiny" weight="regular">
                © 2022 HolaLink - All Rights Reserved.
              </Typography>
            </div>
          </div>
        </RowStyled>
      </div>
    </>
  );
}
