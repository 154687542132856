import { Typography, Theme } from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import FileServices from "../../commons/services/FileServices";
import styled from "@emotion/styled";
import { fileStatus } from "../../commons/constants/file";
import Loading from "../../commons/components/Loading";

const SustenanceContainer = styled.div`
  display: grid;
`;

const SustenanceBox = styled.textarea`
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  margin-bottom: 10px;
  resize: none;
  font-size: 1rem;
  color: ${Theme.colors.mineShaft};
  ::placeholder {
    color: ${Theme.colors.lato};
  }
  &:focus-visible {
    outline: none;
    border: 1px solid ${Theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const SustenanceInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      border: 1px solid ${Theme.colors.azureRadiance};
    }
    input {
      display: none;
    }
    i {
      font-size: 20px;
      color: ${Theme.colors.mineShaft};
    }
    span {
      font-size: 0.9rem;
      color: ${Theme.colors.mineShaft};
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
`;

const DeactivationForm = ({ onChange }) => {
  const [formValue, setFormValue] = useState({});
  const [currentFile, setCurrentFile] = useState({});
  const [fileUploaded, setFileUploaded] = useState(fileStatus.NO_FILE);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    onChange(formValue);
  }, [formValue, onChange]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    setCurrentFile(file);
    uploadFile(file);
  };
  const uploadFile = async (file) => {
    if (!file) return;
    try {
      const filename = `${file.name}-${new Date().getTime()}`;
      let reference = FileServices.getReference(
        "deactivation-reasons",
        filename
      );
      const url = await FileServices.uploadFile(
        file,
        reference,
        setFileUploaded
      );
      setFormValue((prevState) => ({
        ...prevState,
        fileUrl: url,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SustenanceContainer>
      <Typography variant="tiny" weight="bold" className="mb-2 mt-2">
        Sustento
      </Typography>
      <SustenanceInputContainer>
        <label>
          <i className="ri-upload-2-line" />
          <span className="ml-2">Adjuntar archivo</span>
          <input
            type="file"
            name="fileUrl"
            accept="image/jpg,
            image/jpeg,
            image/png,
            application/pdf"
            onChange={handleFileChange}
            placeholder="Seleccione un archivo"
          />
        </label>
      </SustenanceInputContainer>
      {fileUploaded === fileStatus.UPLOADING && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
          }}
        >
          <Loading />
        </div>
      )}
      {fileUploaded === fileStatus.COMPLETED && (
        <p style={{ color: "black", textAlign: "center", marginTop: "12px" }}>
          {currentFile.name}
        </p>
      )}
      <div className="alert alert-secondary mt-3" role="alert">
        Los archivos permitidos son: jpg, jpeg, png, pdf
      </div>
      <Typography variant="tiny" weight="bold" className="mb-2 mt-2">
        Comentarios sobre el sustento
      </Typography>
      <SustenanceBox
        type="text"
        name="comments"
        placeholder="Comentarios"
        onChange={handleChange}
      />
    </SustenanceContainer>
  );
};

export default DeactivationForm;
