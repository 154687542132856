import styled from "@emotion/styled";
import { Button, Flex, Table, Theme } from "@holalink/react-ui-components";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import PricingService from "../services/PricingService";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ButtonContainer = styled.div`
  margin: auto 0;
`;

const SearchAddContainer = styled(Flex)`
  margin-bottom: 20px;
  gap: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .ri-edit-box-line {
    color: ${Theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-delete-bin-line {
    color: ${Theme.colors.pastelRed};
    font-size: 20px;
    cursor: pointer;
  }
`;

const InputSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  input {
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 1rem;
    &:focus-visible {
      outline: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const PricingTable = ({
  setIsAddOpen,
  openEditModal,
  openDeleteModal,
  reload,
  setReloadPricings,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const columns = useMemo(
    () => [
      {
        accessor: "counter",
        Header: "ID",
        width: "60",
        Cell: ({ value }) => {
          if (value && value > 0) {
            return <CounterStyled>#{value}</CounterStyled>;
          } else {
            return <CounterStyled>{"-"}</CounterStyled>;
          }
        },
      },
      {
        accessor: "product",
        Header: "Producto",
        width: "250",
      },
      {
        accessor: "name",
        Header: "Nombre",
        width: "200",
      },
      {
        accessor: "price",
        Header: "Precio",
        width: "150",
      },
      {
        accessor: "currency",
        Header: "Moneda",
        width: "150",
      },
      {
        accessor: "duration",
        Header: "Duración",
        width: "150",
      },
      {
        accessor: "trial",
        Header: "Es Trial",
        width: "250",
        Cell: ({ value }) => {
          return value ? "Sí" : "No";
        },
      },
      {
        accessor: "active",
        Header: "Activo",
        width: "250",
        Cell: ({ value }) => {
          return value ? "Sí" : "No";
        },
      },
      {
        id: "actions",
        accessor: (row) => row.id,
        Header: "Acción",
        width: "100",
        Cell: ({ value }) => {
          return (
            <ActionContainer>
              <i
                className="ri-edit-box-line"
                onClick={() => openEditModal(value)}
              ></i>
              <i
                className="ri-delete-bin-line"
                onClick={() => openDeleteModal(value)}
              ></i>
            </ActionContainer>
          );
        },
      },
    ],
    [openEditModal, openDeleteModal]
  );

  const loadPricings = ({ limit, skip, search }) => {
    setLoading(true);
    PricingService.search(limit, skip, search)
      .then((response) => {
        setData(response.data.data);
        setPageCount(response.data.paging.pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadPricings({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search,
      });
    },
    [search]
  );

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };

  useEffect(() => {
    if (reload) {
      fetchData({ pageSize: 10, pageIndex: 0 });
      setReloadPricings(false);
    }
  }, [reload, fetchData, setReloadPricings]);

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), []);

  return (
    <>
      <SearchAddContainer>
        <InputSearch>
          <i className="ri-search-line" />
          <input
            placeholder="Buscar por nombre"
            type="text"
            name="search"
            onChange={debouncedChangeHandler}
          />
        </InputSearch>
        <ButtonContainer>
          <Button
            variant="primary"
            size="medium"
            onClick={() => setIsAddOpen(true)}
          >
            <i className="ri-add-line" />
            <i className="ri-user-3-line" />
          </Button>
        </ButtonContainer>
      </SearchAddContainer>
      <Table
        pageCount={pageCount}
        fetchData={fetchData}
        loading={loading}
        data={data}
        columns={columns}
        isPaginated
      />
    </>
  );
};

export default PricingTable;
