import styled from "@emotion/styled";
import {
  Button,
  Flex,
  ModalFooter,
  Typography,
} from "@holalink/react-ui-components";
import React, { useEffect, useRef, useState } from "react";
import theme from "../../commons/theme";
import { useForm } from "../../commons/hooks/useForm";
import { css } from "@emotion/react";
import { useCompany } from "../hooks/useCompany";
import DefaultSelect from "../../commons/components/DefaultSelect";
import BillingService from "../services/BillingService";
import FileServices from "../../commons/services/FileServices";
import { toast } from "react-toastify";
import MetadataService from "../../admin-configuration/services/MetadataService";
import { useDispatch } from "react-redux";
import { setNavbarTitle } from "../../store/common/actions/commonActions";

const OneColumnContainer = styled(Flex)`
  margin-bottom: 20px;
`;

const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${(props) => props.error !== "" && InputError}
`;


const ErrorLabel = styled.span`
  color: ${theme.colors.pastelRed};
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
`;

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const ContentForm = styled.div`
  width: 100%;
  margin: 12px 0;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const MetadataContainer = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 12px;
`

const currencyOptions = [
  {
    label: "PEN - Soles",
    value: "PEN",
  },
  {
    label: "USD - Dólares",
    value: "USD",
  },
];

const initialFormValues = {
  companyId: "",
  cardsQuantity: 0,
  currency: "",
  totalPrice: 0,
  voucher: null,
};
const initialErrors = {
  companyId: "",
  cardsQuantity: "",
  currency: "",
  totalPrice: "",
  voucher: null,
};

const validations = {
  companyId: {
    validate: (value) => {
      if (!value) {
        return "Seleccione una compañía";
      }
      return "";
    },
  },
  cardsQuantity: {
    validate: (value) => {
      if (!/^[0-9]+$/.test(value.toString())) {
        return "Solo ingresar números enteros positivos";
      }
      if (value < 1) {
        return "La cantidad de tarjetas debe ser mayor a 0";
      }
      return "";
    },
  },
  currency: {
    validate: (value) => {
      if (!value) {
        return "Seleccione un tipo de moneda";
      }
      return "";
    },
  },
  totalPrice: {
    validate: (value) => {
      if (!/^[0-9]+$/.test(value.toString())) {
        return "Solo ingresar números enteros positivos";
      }
      if (value < 1) {
        return "El precio total debe ser mayor a 0";
      }
      return "";
    },
  },
  voucher: {
    validate: (file) => {
      if (!file) {
        return "Seleccione un voucher";
      }
      const validFileTypes = ['image/jpeg', 'image/jpg'];
      if (!validFileTypes.includes(file.type)) {
        return "El voucher debe ser un archivo .jpg o .jpeg";
      }

      const maxSize = 1000000; // 1MB
      if (file.size > maxSize) {
        return "El voucher debe tener un tamaño máximo de 1MB";
      }

      return "";
    }
  },
}

export const AdditionalPaymentForm = () => {
  const { formValues, errors, handleChange, handleFile, resetForm, validate } = useForm({
    initialFormValues,
    initialErrors,
    validations
  });
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const fileRef = useRef(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { companyOptions, loading } = useCompany({ companyType: "business" });

  const [formattedMetadata, setFormattedMetadata] = useState({});
  const [loadingMetadata, setLoadingMetadata] = useState(false);

  const [suggestedAmounts, setSuggestedAmounts] = useState([]);
  const dispatch = useDispatch()

  const uploadVoucher = async (file) => {
    if (!file) return null;
    const filename = `${file.name}-${new Date().getTime()}`;
    const reference = FileServices.getReference("voucher", filename);
    let url = null;
    try {
      url = await FileServices.uploadFile(file, reference);
    } catch (error) {
      FileServices.deleteFile(reference);
    }
    return { url, reference };
  };

  const reset = () => {
    resetForm();
    fileRef.current.value = null;
  };

  const saveAdditionalPayment = () => {
    const isValid = validate();
    if (!isValid) return;
    const { companyId, ...body } = formValues;
    setLoadingSubmit(true);
    uploadVoucher(formValues.voucher).then((response) => {
      body.voucher = response.url;
      BillingService.saveAdditionalPayment(formValues.companyId, timezone, body)
        .then(() => {
          reset();
          toast.success(`Pago adicional creado exitosamente`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          FileServices.deleteFile(response.reference);
        })
        .finally(() => setLoadingSubmit(false));
    });
  };

  const formatMetadata = (metadata) => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentMonthString =
      currentMonth > 9 ? currentMonth.toString() : `0${currentMonth}`;
    const currentYear = today.getFullYear();
    return {
      cutoffDay: `${metadata.cutoffDay}/${currentMonthString}/${currentYear}`,
    };
  };



  const getSuggestedAmounts = async () => {
    let companyIdError = ""
    let cardsQuantityError = ""
    companyIdError = validations['companyId'].validate(formValues['companyId'])
    cardsQuantityError = validations['cardsQuantity'].validate(formValues['cardsQuantity'])

    if (companyIdError == "" && cardsQuantityError == "") {
      BillingService.getSuggestedAmountsForAdditionalPayment(
        formValues.companyId,
        formValues.cardsQuantity
      ).then((response) => {
        setSuggestedAmounts(response.data.suggestedAmounts);
      });
      return;
    }

    if (companyIdError !== "") {
      toast.error(`Compañía: ${companyIdError}`, {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        allowHtml: true
      });
    }
    if (cardsQuantityError !== "") {
      toast.error(`Cantidad de tarjetas: ${cardsQuantityError}`, {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        allowHtml: true
      });
    }


  };

  useEffect(() => {
    dispatch(setNavbarTitle("Tarjetas HolaLink - Pagos Adicionales"));
    const loadMetadata = async () => {
      setLoadingMetadata(true);
      MetadataService.getMetadata()
        .then((response) => {
          setFormattedMetadata(formatMetadata(response.data));
        })
        .catch((error) => console.log(error))
        .finally(() => setLoadingMetadata(false));
    };

    loadMetadata();
  }, [dispatch]);

  return (
    <Flex gap='100px'>
      <OneColumnContainer>
        <div>
          <ContentForm>
            <label>Empresa*</label>
            {loading ? (
              <span>Cargando...</span>
            ) : (
              <DefaultSelect
                name='companyId'
                value={formValues.companyId}
                placeholder='Seleccionar una empresa'
                onChange={handleChange}
                options={companyOptions}
                error={errors?.companyId}
              />
            )}
            {errors?.companyId && <ErrorLabel>{errors?.companyId}</ErrorLabel>}
          </ContentForm>
          <ContentForm>
            <label>Cantidad de tarjetas</label>
            <InputForm
              name='cardsQuantity'
              type='number'
              value={formValues.cardsQuantity}
              placeholder='Cantidad de tarjetas'
              onChange={handleChange}
              error={errors?.cardsQuantity}
            />
            {errors?.cardsQuantity && (
              <ErrorLabel>{errors?.cardsQuantity}</ErrorLabel>
            )}
          </ContentForm>
          <ContentForm>
            <label>Tipo de moneda</label>
            <DefaultSelect
              name='currency'
              value={formValues.currency}
              placeholder='Seleccionar un tipo de moneda'
              onChange={handleChange}
              options={currencyOptions}
              error={errors?.currency}
            />
            {errors?.currency && <ErrorLabel>{errors?.currency}</ErrorLabel>}
          </ContentForm>
          <ContentForm>
            <label>Precio total</label>
            <InputForm
              name='totalPrice'
              type='number'
              placeholder='Precio total'
              value={formValues.totalPrice}
              onChange={handleChange}
              error={errors?.totalPrice}
            />
            {errors?.totalPrice && (
              <ErrorLabel>{errors?.totalPrice}</ErrorLabel>
            )}
          </ContentForm>
          <ContentForm>
            <label>Voucher</label>
            <input
              ref={fileRef}
              type='file'
              accept='image/*'
              id='voucher'
              name='voucher'
              onChange={handleFile}
            />
            {errors?.voucher && <ErrorLabel>{errors?.voucher}</ErrorLabel>}
          </ContentForm>
          <ModalFooter>
            <Button
              variant='primary'
              size='small'
              onClick={saveAdditionalPayment}
            >
              Guardar
            </Button>
            {loadingSubmit && (
              <Typography variant='small'>Cargando...</Typography>
            )}
          </ModalFooter>
        </div>
      </OneColumnContainer>
      <OneColumnContainer>
        {!loadingMetadata && (
          <MetadataContainer>
            <Typography variant='small' weight="bold">Información de pagos</Typography>
            <div>
              <Typography variant='tiny' weight="bold">
                Fecha de corte:
              </Typography>
              <Typography variant='small'>{formattedMetadata.cutoffDay}</Typography>
            </div>
            <div style={{ marginTop: '186px' }}></div>
            <Button
              variant='primary'
              size='small'
              onClick={getSuggestedAmounts}
            >
              Calcular monto sugerido
            </Button>

            {!!suggestedAmounts.length && (
              <div>
                <Typography variant='tiny' weight="bold">Monto sugerido:</Typography>
                {suggestedAmounts.map(({ currency, amount }, index) => {
                  return (
                    <Typography variant='small' key={index.toString()}>
                      {currency} {amount}
                    </Typography>
                  );
                })}
              </div>
            )}
          </MetadataContainer>
        )}
      </OneColumnContainer>
    </Flex>
  );
};
