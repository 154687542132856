import { Flex } from "@holalink/react-ui-components";

export function NotFound() {
  return (
    <Flex>
      <div>
        <h1>404 Not Found</h1>
      </div>
      <div>
        <h4>La ruta que ha ingresado no existe</h4>
      </div>
    </Flex>
  );
}
