import React from "react";
import styled from "@emotion/styled";
import theme from "../theme";

const SelectStyled = styled.select`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  transition: border-color 0.3s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const DefaultSelect = ({ options, ...props }) => {
  return (
    <div className="default-select w-100">
      <SelectStyled {...props}>
        <option value="" hidden>
          {props.placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
        {props?.errors && (
          <div className="invalid-feedback">{props.errors}</div>
        )}
      </SelectStyled>
    </div>
  );
};

export default DefaultSelect;
