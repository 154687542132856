import styled from "@emotion/styled";
import { Button, Flex, Table } from "@holalink/react-ui-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import EmployeeService from "../services/EmployeeService";
import "remixicon/fonts/remixicon.css";
import theme from "../../commons/theme";
import Tooltip from "../../commons/components/Tooltip";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";
import { debounce } from "lodash";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 auto;
`;

const CompanyStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    height: 30px;
    border-radius: 10px;
  }
`;

const SearchAddContainer = styled(Flex)`
  margin-bottom: 20px;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  margin: auto 0;
`;

const InputSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  input {
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 1rem;
    &:focus-visible {
      outline: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .ri-edit-box-line {
    color: ${theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-delete-bin-line {
    color: ${theme.colors.pastelRed};
    font-size: 20px;
    cursor: pointer;
  }
`;

const TooltipStyled = styled(Tooltip)`
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EmployeesTable = ({
  setIsAddOpen,
  openEditModal,
  openDeleteModal,
  reload,
  setReloadEmployees,
  accountType,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const columns = useMemo(
    () => [
      {
        accessor: "counter",
        Header: "ID",
        width: "60",
        Cell: ({ value }) => {
          if (value && value > 0) {
            return <CounterStyled>#{value}</CounterStyled>;
          } else {
            return <CounterStyled>{"-"}</CounterStyled>;
          }
        },
      },
      {
        accessor: "company",
        Header: "Empresa",
        width: "200",
        defaultCanSort: true,
        Cell: ({ value }) => {
          return value ? (
            <CompanyStyled>
              {value.logoUrl && <img src={value.logoUrl} alt={value.name} />}
              {value.name}
            </CompanyStyled>
          ) : (
            ""
          );
        },
      },
      {
        accessor: "date",
        Header: "Fecha de Registro",
        width: "200",
      },
      {
        id: "names",
        accessor: (row) => `${row.name} ${row.lastname}`,
        Header: "Nombre",
        width: "300",
      },
      {
        accessor: "email",
        Header: "Correo",
        width: "250",
        style: "overflow: hidden; text-overflow: ellipsis;",
        Cell: ({ value }) => {
          return value ? (
            <TooltipStyled text={value}>
              <span>{value}</span>
            </TooltipStyled>
          ) : (
            ""
          );
        },
      },
      {
        accessor: "phone",
        Header: "Teléfono",
        width: "140",
      },
      {
        accessor: "role",
        Header: "Rol",
        width: "150",
      },
      {
        id: "actions",
        accessor: (row) => row.id,
        Header: "Acción",
        width: "100",
        Cell: ({ value }) => {
          return (
            <ActionContainer>
              <i
                className="ri-edit-box-line"
                onClick={() => openEditModal(value)}
              ></i>
              <i
                className="ri-delete-bin-line"
                onClick={() => openDeleteModal(value)}
              ></i>
            </ActionContainer>
          );
        },
      },
    ],
    [openEditModal, openDeleteModal]
  );

  const loadEmployees = ({ limit, skip, search, accountType }) => {
    setLoading(true);
    EmployeeService.search(limit, skip, search, accountType)
      .then((response) => {
        setData(response.data.data);
        setPageCount(response.data.paging.pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadEmployees({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search,
        accountType,
      });
    },
    [search, accountType]
  );

  const handleSearch = (event, one, two, three) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };

  useEffect(() => {
    if (reload) {
      fetchData({ pageSize: 10, pageIndex: 0 });
      setReloadEmployees(false);
    }
  }, [reload, fetchData, setReloadEmployees]);

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), []);

  return (
    <>
      <SearchAddContainer>
        <InputSearch>
          <i className="ri-search-line" />
          <input
            placeholder="Buscar por nombres, correo, teléfono o empresa"
            type="text"
            name="search"
            onChange={debouncedChangeHandler}
          />
        </InputSearch>
        {accountType === ACCOUNT_TYPES.BUSINESS && (
          <ButtonContainer>
            <Button
              variant="primary"
              size="medium"
              onClick={() => setIsAddOpen(true)}
            >
              <i className="ri-add-line" />
              <i className="ri-user-3-line" />
            </Button>
          </ButtonContainer>
        )}
      </SearchAddContainer>
      <Table
        pageCount={pageCount}
        fetchData={fetchData}
        loading={loading}
        data={data}
        columns={columns}
        isPaginated
      />
    </>
  );
};

export default EmployeesTable;
