import styled from "@emotion/styled";
import { Flex } from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import PlanService from "../../plans/services/PlanService";
import theme from "../../commons/theme";
import { LogoUploadInput } from "../../commons/components/LogoUploadInput";
import FileServices from "../../commons/services/FileServices";
import { css } from "@emotion/react";
import DefaultSelect from "../../commons/components/DefaultSelect";
import { format, getDate, parse } from "date-fns";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const TwoColumnContainer = styled(Flex)`
  gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
  > div {
    flex: 50%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const OneColumnContainer = styled(Flex)`
  margin-bottom: 20px;
`;

const ContentForm = styled.div`
  width: 100%;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${(props) => props.error !== "" && InputError}
`;

const ErrorLabel = styled.span`
  color: ${theme.colors.pastelRed};
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
`;

const validations = {
  name: {
    validate: (name) => {
      if (!name) {
        return "El nombre es requerido";
      }
      if (name.trim() === "" || !/^[a-zA-ZÀ-ÿ\s0-9]{1,40}$/.test(name)) {
        return "Nombre de empresa inválido";
      } else if (name.length < 3) {
        return "El nombre debe tener al menos 3 caracteres";
      }
      return "";
    },
  },
  address: {
    validate: (address) => {
      if (!address) {
        return "La dirección es requerida";
      }
      if (address.trim() === "" || address.length < 3) {
        return "La dirección debe tener al menos 3 caracteres";
      }
      return "";
    },
  },
  phone: {
    validate: (phone) => {
      if (!phone) {
        return "El teléfono es requerido";
      }
      if (phone.trim() === "" || !/^\+\d{10,11}$/.test(phone)) {
        return "El teléfono debe ser válido";
      } else if (phone.length < 9) {
        return "El teléfono debe tener al menos 9 números";
      }
      return "";
    },
  },
  ruc: {
    validate: (ruc) => {
      if (!ruc) {
        return "El RUC es requerido";
      }
      if (ruc.trim() === "" || !/^[0-9]{1,40}$/.test(ruc)) {
        return "El RUC solo debe contener números";
      } else if (ruc.length < 11) {
        return "El RUC debe tener al menos 11 números";
      }
      return "";
    },
  },
  initialBillingCycle: {
    validate: (initialBillingCycle) => {
      if (!initialBillingCycle) {
        return "El ciclo es requerido";
      }
      const date = parse(initialBillingCycle, "yyyy-MM-dd", new Date());
      if (getDate(date) > 28) {
        return "El día del ciclo debe ser un día entre 1 y 28";
      }
      return "";
    },
  },
  mainEmail: {
    validate: (mainEmail) => {
      if (!mainEmail) {
        return "El correo es requerido";
      }
      if (
        mainEmail.trim() === "" ||
        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9-.]+$/.test(mainEmail)
      ) {
        return "El correo no es válido";
      }
      return "";
    },
  },
  webUrl: {
    validate: (webUrl) => {
      if (!webUrl) {
        return "La url es requerida";
      }
      if (
        webUrl.trim() === "" ||
        !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
          webUrl
        )
      ) {
        return "La url no es válida";
      }
      return "";
    },
  },
  plan: {
    validate: (plan) => {
      if (!plan) {
        return "El plan es requerido";
      }
      return "";
    },
  },
  logoUrl: {
    validate: (logoUrl) => {
      if (!logoUrl) {
        return "El logo es requerido";
      }
      return "";
    },
  },
};

const CompanyForm = ({ data, onChange }) => {
  const [formValue, setFormValue] = useState({});
  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [errors, setErrors] = useState({
    name: "",
    address: "",
    phone: "",
    ruc: "",
    initialBillingCycle: "",
    mainEmail: "",
    webUrl: "",
    plan: "",
  });

  const validateField = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: validations[name].validate(value),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  useEffect(() => {
    if (!data) return;
    setData(data);
  }, [data]);

  useEffect(() => {
    loadPlans();
    const isCreatingNewCompany = !data;
    if (isCreatingNewCompany) {
      noYearInput();
    }
  }, [data]);

  useEffect(() => {
    onChange(formValue, errors);
  }, [formValue, errors, onChange]);

  const noYearInput = () => {
    const year = new Date().getFullYear();
    const input = document.getElementById("initialBillingCycle");
    if (input) {
      input.setAttribute("min", year + "-01-01");
      input.setAttribute("max", year + "-12-31");
    }
  };


  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const setData = (initialFormData) => {
    setFormValue({
      name: initialFormData.name,
      address: initialFormData.address,
      phone: initialFormData.phone,
      ruc: initialFormData.ruc,
      mainEmail: initialFormData.mainEmail,
      webUrl: initialFormData.webUrl,
      plan: initialFormData.plan,
      logoUrl: initialFormData.logoUrl,
      initialBillingCycle: !!initialFormData.initialBillingCycle
        ? format(
            new Date(initialFormData.initialBillingCycle).addDays(1),
            "yyyy-MM-dd"
          )
        : "",
    });
  };

  const loadPlans = () => {
    PlanService.getAll().then((response) => {
      setPlans(
        response.data.map((plan) => ({
          value: plan.id,
          label: plan.name,
        }))
      );
      setLoadingPlans(false);
    });
  };

  const logoUpload = async (logoFile) => {
    if (formValue.logoUrl !== undefined || formValue.logoUrl !== "") {
      if (logoFile) {
        let url = "";
        try {
          let filename = `${logoFile.name}-${new Date().getTime()}`;
          let reference = FileServices.getReference("image/*", filename);
          url = await FileServices.uploadFile(logoFile, reference);
          setFormValue((prevState) => ({
            ...prevState,
            logoUrl: url,
          }));
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" mb="2">
        <LogoUploadInput
          onHandleImage={logoUpload}
          currentLogoUrl={formValue.logoUrl}
          formType="company"
          error={errors?.logoUrl}
        />
        {errors?.logoUrl && <ErrorLabel>{errors?.logoUrl}</ErrorLabel>}
      </Flex>
      <TwoColumnContainer>
        <ContentForm>
          <label>Nombre*</label>
          <InputForm
            name="name"
            type="text"
            placeholder="Nombre"
            defaultValue={formValue.name}
            onChange={handleChange}
            error={errors?.name}
          />
          {errors?.name && <ErrorLabel>{errors?.name}</ErrorLabel>}
        </ContentForm>
        {!data || data.companyType === ACCOUNT_TYPES.BUSINESS ? (
          <ContentForm>
            <label>Dirección*</label>
            <InputForm
              name="address"
              type="text"
              placeholder="Dirección"
              defaultValue={formValue.address}
              onChange={handleChange}
              error={errors?.address}
            />
            {errors?.address && <ErrorLabel>{errors?.address}</ErrorLabel>}
          </ContentForm>
        ) : (
          <></>
        )}
      </TwoColumnContainer>
      <TwoColumnContainer>
        <ContentForm>
          <label>Teléfono*</label>
          <InputForm
            name="phone"
            type="text"
            maxLength={12}
            placeholder="Teléfono"
            defaultValue={formValue.phone}
            onChange={handleChange}
            error={errors?.phone}
          />
          {errors?.phone && <ErrorLabel>{errors?.phone}</ErrorLabel>}
        </ContentForm>
        {!data || data.companyType === ACCOUNT_TYPES.BUSINESS ? (
          <ContentForm>
            <label>RUC*</label>
            <InputForm
              name="ruc"
              type="text"
              placeholder="RUC"
              defaultValue={formValue.ruc}
              onChange={handleChange}
              error={errors?.ruc}
            />
            {errors?.ruc && <ErrorLabel>{errors?.ruc}</ErrorLabel>}
          </ContentForm>
        ) : (
          <></>
        )}
      </TwoColumnContainer>
      {!data || data.companyType === ACCOUNT_TYPES.BUSINESS ? (
        <OneColumnContainer>
          <ContentForm>
            <label>Ciclo*</label>
            <InputForm
              id="initialBillingCycle"
              name="initialBillingCycle"
              type="date"
              defaultValue={formValue.initialBillingCycle}
              onChange={handleChange}
              error={errors.initialBillingCycle}
            />
            {errors?.initialBillingCycle && (
              <ErrorLabel>{errors?.initialBillingCycle}</ErrorLabel>
            )}
          </ContentForm>
        </OneColumnContainer>
      ) : (
        <></>
      )}
      <OneColumnContainer>
        <ContentForm>
          <label>Email*</label>
          <InputForm
            name="mainEmail"
            type="text"
            disabled={data?.companyType === ACCOUNT_TYPES.PERSONAL}
            placeholder="Email"
            defaultValue={formValue.mainEmail}
            onChange={handleChange}
            error={errors?.mainEmail}
          />
          {errors?.mainEmail && <ErrorLabel>{errors?.mainEmail}</ErrorLabel>}
        </ContentForm>
      </OneColumnContainer>
      {!data || data.companyType === ACCOUNT_TYPES.BUSINESS ? (
        <OneColumnContainer>
          <ContentForm>
            <label>Url*</label>
            <InputForm
              name="webUrl"
              type="text"
              placeholder="Url"
              defaultValue={formValue.webUrl}
              onChange={handleChange}
              error={errors?.webUrl}
            />
            {errors?.webUrl && <ErrorLabel>{errors?.webUrl}</ErrorLabel>}
          </ContentForm>
        </OneColumnContainer>
      ) : (
        <></>
      )}
      {!data || data.companyType === ACCOUNT_TYPES.BUSINESS ? (
        <OneColumnContainer>
          <ContentForm>
            <label>Paquete*</label>
            {loadingPlans ? (
              <span>Cargando...</span>
            ) : (
              <DefaultSelect
                name="plan"
                defaultValue={formValue.plan}
                placeholder="Seleccionar un Paquete"
                onChange={handleChange}
                options={plans}
                error={errors?.plan}
              />
            )}
            {errors?.plan && <ErrorLabel>{errors?.plan}</ErrorLabel>}
          </ContentForm>
        </OneColumnContainer>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default CompanyForm;
