import { useState } from "react";
import Cropper from "react-easy-crop";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@holalink/react-ui-components";

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });
}

async function getCroppedImage(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    return null;
  }

  canvas.width = image.width;
  canvas.height = image.height;

  context.scale(1, 1);
  context.drawImage(image, 0, 0);

  const data = context.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  context.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      file.name = "cropped-image.jpeg";
      resolve({ file: file, url: URL.createObjectURL(file) });
    }, "image/jpeg");
  });
}

const RoundImageCropper = ({
  imageSrc,
  openCropper,
  closeCropper,
  setCroppedImage,
  cropType,
  loading = false,
  withLoading = false,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImage(imageSrc, croppedAreaPixels);
      await setCroppedImage(file, url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {openCropper && (
        <Modal size="large">
          <ModalHeader>Ajustar imagen</ModalHeader>
          <ModalContent>
            <div style={{ height: "320px" }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                cropShape={cropType}
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
              />
            </div>
          </ModalContent>
          <ModalFooter
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <div>
              {/* <p
              >
                Zoom
              </p>
              <Slider
              size="small"
              aria-label="slider"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
            /> */}
            </div>
            <div
              style={{ width: "100%", textAlign: "center", marginLeft: "0px" }}
            >
              <Button
                variant="primary"
                size="small"
                onClick={cropImage}
                style={{ margin: "0px 12px" }}
              >
                Confirmar
              </Button>
              <Button
                variant="primary"
                size="small"
                onClick={() => {
                  closeCropper();
                }}
                style={{ margin: "0px 12px" }}
              >
                Cerrar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default RoundImageCropper;
