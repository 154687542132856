import { put } from "redux-saga/effects";
import { logout } from "../../auth/actions/authActions";

export function* clearStoreHandler(action) {
  try {
    yield put(logout());
  } catch (error) {
    console.log(error);
  }
}
