import { Typography } from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyService from "../../companies/services/CompanyService";
import CompanyBillingsTable from "../components/CompanyBillingsTable";

export function BillingDetail() {
  const [reloadBillings, setReloadBillings] = useState(false);
  const [company, setCompany] = useState(null);
  const { companyId } = useParams();

  useEffect(() => {
    CompanyService.getById(companyId).then((response) => {
      setCompany(response.data);
    });
  }, [companyId]);

  return (
    <>
      <Typography variant='small' weight='bold' my='3'>
        Empresa: {company?.name}
      </Typography>
      {company !== null && (
        <CompanyBillingsTable
          company={company}
          reload={reloadBillings}
          setReloadBillings={setReloadBillings}
        />
      )}
    </>
  );
}
