import styled from "@emotion/styled";
import { Flex, Theme, Typography } from "@holalink/react-ui-components";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as Camera } from "./../assets/img/camera.svg";
import ReactSwitch from "react-switch";
import { format } from "date-fns";
import Loading from "../../commons/components/Loading";
import DefaultSelect from "../../commons/components/DefaultSelect";
import BillingService from "../services/BillingService";

const BillingInfoHeader = styled.div`
  background-color: ${Theme.colors.azureRadiance};
  padding: 10px;
  p {
    text-align: center;
    color: white;
  }
`;
const BillingInfoContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
`;

const CommentsContainer = styled.div`
  border-radius: 20px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

const VoucherContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  flex: 50%;
  cursor: pointer;
  input {
    height: 0px;
    width: 0px;
  }
  transition: background 0.3s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: white;
  }
  .camera-icon {
    filter: invert(72%) sepia(38%) saturate(4350%) hue-rotate(209deg)
      brightness(105%) contrast(100%);
  }
  @media screen and (max-width: 768px) {
    gap: 0px;
  }
`;

const VoucherFileName = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: center;
`;

const FormContainer = styled(Flex)`
  max-width: 615px;
  width: 100%;
  padding: 35px;
  width: 100%;
  @media (min-width: 768px) {
    min-width: 470px;
  }
`;

const CommentsInput = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px solid ${Theme.colors.mineShaft};
  border-radius: 10px;
  resize: none;
  padding: 10px;
  font-size: 1rem;
  color: ${Theme.colors.mineShaft};
  ::placeholder {
    color: ${Theme.colors.mineShaft};
  }
  &:focus-visible {
    outline: none;
    border: 1px solid ${Theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ContentComments = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const BillingForm = ({
  billingRow,
  onChange,
  onVoucherChange,
  voucherLoading,
}) => {
  const [formValue, setFormValue] = useState({});
  const [billing, setBilling] = useState(null);
  const [voucherFile, setVoucherFile] = useState(undefined);
  const [billingCycles, setBillingCycles] = useState([]);
  const [loadingBillings, setLoadingBillings] = useState(true);

  const populateBillingCycles = (row) => {
    const validBillingCycles = [];
    const firstMonth = row?.firstMonth;
    const secondMonth = row?.secondMonth;
    const thirdMonth = row?.thirdMonth;
    if (firstMonth && !firstMonth.billingNotStarted && +firstMonth.amount > 0) {
      validBillingCycles.push({
        value: firstMonth.billingCycle,
        label: format(new Date(firstMonth.billingCycle), "dd/MM/yyyy"),
      });
    }
    if (
      secondMonth &&
      !secondMonth.billingNotStarted &&
      +secondMonth.amount > 0
    ) {
      validBillingCycles.push({
        value: secondMonth.billingCycle,
        label: format(new Date(secondMonth.billingCycle), "dd/MM/yyyy"),
      });
    }
    if (thirdMonth && !thirdMonth.billingNotStarted && +thirdMonth.amount > 0) {
      validBillingCycles.push({
        value: thirdMonth.billingCycle,
        label: format(new Date(thirdMonth.billingCycle), "dd/MM/yyyy"),
      });
    }

    return validBillingCycles;
  };
  const setData = useCallback(() => {
    setBillingCycles(populateBillingCycles(billingRow));
    setLoadingBillings(false);
    setFormValue({
      company: billingRow?.id,
      billingCycle: billing?.billingCycle,
      paid: billing?.paid,
      comments: billing?.comments,
      voucher: billing?.voucher,
      amount: +(billing?.amount || 0),
      currency: billing?.currency,
      id: billing?.id,
    });
  }, [billing, billingRow]);

  const setEmptyBilling = useCallback(() => {
    setBilling({
      billingCycle: billingRow.firstMonth?.billingCycle,
      paid: false,
      comments: "",
      voucher: null,
      amount: +billingRow.firstMonth?.amount,
      currency: billingRow.firstMonth?.currency,
    });
  }, [billingRow]);

  const loadBillingData = useCallback(
    (billingId) => {
      if (!billingId) {
        setEmptyBilling();
        return;
      }
      BillingService.getById(billingId)
        .then((response) => {
          setBilling(response.data);
        })
        .catch((err) => {
          setEmptyBilling();
        });
    },
    [setEmptyBilling]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePaidChange = (value) => {
    setFormValue((prevState) => ({
      ...prevState,
      paid: value,
    }));
  };

  const handleVoucherClick = () => {
    const voucherInput = document.getElementById("voucherInput");
    voucherInput.click();
  };

  const handleVoucherChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setVoucherFile(undefined);
      return;
    }

    const file = files[0];
    setVoucherFile(file);
    onVoucherChange(file);
  };

  const handleBillingCycleChange = (e) => {
    const value = e.target.value;
    setFormValue((prevState) => ({
      ...prevState,
      billingCycle: value,
    }));
    const billingId = getBillingIdByBillingCycle(value);
    loadBillingData(billingId);
  };

  const getBillingIdByBillingCycle = (billingCycle) => {
    if (billingRow.firstMonth.billingCycle === billingCycle)
      return billingRow.firstMonth.id;
    if (billingRow.secondMonth.billingCycle === billingCycle)
      return billingRow.secondMonth.id;
    if (billingRow.thirdMonth.billingCycle === billingCycle)
      return billingRow.thirdMonth.id;
    return null;
  };

  useEffect(() => {
    setData();
  }, [setData, billing]);

  useEffect(() => {
    onChange(formValue);
  }, [formValue, onChange]);

  useEffect(() => {
    const billingId = billingRow.firstMonth?.id;
    if (billingId) {
      loadBillingData(billingId);
    } else {
      setEmptyBilling();
    }
  }, [billingRow, loadBillingData, setEmptyBilling]);

  return (
    <FormContainer flexDirection="column">
      {!loadingBillings && billingCycles && billingCycles.length === 0 ? (
        <Flex justifyContent="center" pb="3">
          <Typography variant="small" weight="bold">
            No tiene facturas pendientes
          </Typography>
        </Flex>
      ) : (
        <>
          <Flex justifyContent="center" pb="3">
            <Typography variant="h5" weight="bold">
              {billingRow?.name}
            </Typography>
          </Flex>
          <Flex mb="3">
            {loadingBillings || !formValue.billingCycle ? (
              <span>Cargando facturas...</span>
            ) : (
              <DefaultSelect
                name="billingCycle"
                placeholder="Seleccionar ciclo"
                options={billingCycles}
                className="select-search"
                defaultValue={formValue.billingCycle}
                onChange={handleBillingCycleChange}
              />
            )}
          </Flex>
          <BillingInfoContainer>
            <BillingInfoHeader>
              <Typography variant="small" weight="bold">
                {formValue.billingCycle
                  ? format(new Date(formValue?.billingCycle), "dd/MM/yyyy")
                  : ""}
              </Typography>
            </BillingInfoHeader>
            <Flex justifyContent="center" p="3">
              <Typography variant="h5" weight="bold">
                {formValue?.currency} {formValue?.amount}
              </Typography>
            </Flex>
          </BillingInfoContainer>
          <ContentComments>
            <VoucherContainer onClick={handleVoucherClick}>
              <Camera
                title={voucherFile?.name}
                width="50px"
                className="camera-icon"
              />
              {voucherLoading ? <Loading /> : null}
              {voucherFile && !voucherLoading ? (
                <VoucherFileName
                  title={voucherFile.name}
                  variant="xtiny"
                  weight="regular"
                  mt="2"
                >
                  {voucherFile.name}
                </VoucherFileName>
              ) : formValue.voucher ? (
                <VoucherFileName variant="xtiny" weight="regular" mt="2">
                  <a target="_blank" rel="noreferrer" href={formValue.voucher}>
                    Ver voucher
                  </a>
                </VoucherFileName>
              ) : (
                ""
              )}

              <Typography
                variant="xtiny"
                weight="regular"
                mt="2"
                style={{
                  textAlign: "center",
                }}
              >
                Adjuntar voucher
              </Typography>

              <input
                id="voucherInput"
                type="file"
                name="voucher"
                onChange={handleVoucherChange}
              />
            </VoucherContainer>
            <CommentsContainer>
              <Typography variant="xs" weight="bold" mb="2">
                Comentarios
              </Typography>
              <CommentsInput
                name="comments"
                value={formValue.comments}
                onChange={handleChange}
              ></CommentsInput>
            </CommentsContainer>
          </ContentComments>
          <Flex justifyContent="center">
            <Typography variant="tiny" weight="regular" pr="2">
              Pagó
            </Typography>
            <label htmlFor="material-switch text-center">
              <ReactSwitch
                checked={formValue.paid || false}
                onChange={handlePaidChange}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="material-switch"
              />
            </label>
          </Flex>
        </>
      )}
    </FormContainer>
  );
};

export default BillingForm;
