import firebase from 'firebase/compat/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PROJECT_ID,
    projectId: process.env.REACT_APP_AUTH_DOMAIN,
    storageBucket: process.env.REACT_APP_BUCKET_URL,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
  };


const app = firebase.initializeApp(firebaseConfig)
export const storage = getStorage(app)


