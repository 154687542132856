import styled from "@emotion/styled";
import { Flex, Table } from "@holalink/react-ui-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import theme from "../../commons/theme";
import { debounce } from "lodash";
import BillingService from "../services/BillingService";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 auto;
`;

const CompanyStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    height: 30px;
    border-radius: 10px;
  }
`;

const SearchAddContainer = styled(Flex)`
  margin-bottom: 20px;
  gap: 20px;
`;

const InputSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  input {
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 1rem;
    &:focus-visible {
      outline: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .ri-edit-box-line {
    color: ${theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-login-box-line {
    font-size: 20px;
    cursor: pointer;
  }
`;

const BillingsTable = ({
  openEditModal,
  goToBillingDetails,
  reload,
  accountType,
  setReloadBillings,
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const columns = useMemo(
    () => [
      {
        accessor: "counter",
        Header: "ID",
        width: "60",
        Cell: ({ value }) => {
          if (value && value > 0) {
            return <CounterStyled>#{value}</CounterStyled>;
          } else {
            return <CounterStyled>{"-"}</CounterStyled>;
          }
        },
      },
      {
        id: "name",
        accessor: (row) => ({ logoUrl: row.logoUrl, name: row.name }),
        Header: "Empresa",
        width: "200",
        Cell: ({ value }) => {
          return value ? (
            <CompanyStyled>
              {value.logoUrl && <img src={value.logoUrl} alt={value.name} />}
              {value.name}
            </CompanyStyled>
          ) : (
            ""
          );
        },
      },
      {
        accessor: "admin",
        Header: "Administrador",
        width: "300",
      },
      {
        accessor: "mainEmail",
        Header: "Correo",
        width: "250",
      },
      {
        accessor: "phone",
        Header: "Teléfono",
        width: "140",
      },
      {
        id: "remainingDays",
        accessor: (row) => row.paymentInfo?.remainingDays,
        Header: "Días restantes",
        width: "150",
        Cell: ({ value }) => {
          return value ? `${value}` : "-";
        },
      },
      {
        id: "endDate",
        accessor: (row) => row.paymentInfo?.endDate,
        Header: "Fecha vencimiento",
        width: "200",
        Cell: ({ value }) => {
          const date = new Date(value);
          date.setDate(date.getDate() + 1);
          return value ? format(date, "dd/MM/yyyy") : "";
        },
      },
      {
        accessor: "unpaid",
        Header: "Falta pagar",
        width: "150",
        Cell: ({ value }) => {
          return value ? `Sí` : "No";
        },
      },
      {
        id: "actions",
        accessor: (row) => row,
        Header: "Acción",
        width: "100",
        Cell: ({ value }) => {
          return (
            <ActionContainer>
              <i
                title="Ir al detalle"
                className="ri-login-box-line"
                onClick={() => goToBillingDetails(value.id)}
              ></i>
              {/* <i
                title="Agregar o Editar facturación"
                className="ri-edit-box-line"
                onClick={() => openEditModal(value)}
              ></i> */}
            </ActionContainer>
          );
        },
      },
    ],
    [goToBillingDetails]
  );

  const loadBillings = ({ limit, skip, search, accountType }) => {
    setLoading(true);
    BillingService.search(limit, skip, search, accountType, timezone)
      .then((response) => {
        setData(response.data.data);
        setPageCount(response.data.paging.pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadBillings({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search,
        accountType,
      });
    },
    [search, accountType]
  );

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };

  useEffect(() => {
    if (reload) {
      fetchData({ pageSize: 10, pageIndex: 0 });
      setReloadBillings(false);
    }
  }, [reload, fetchData, setReloadBillings]);

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), []);

  return (
    <>
      <SearchAddContainer>
        <InputSearch>
          <i className="ri-search-line" />
          <input
            placeholder="Buscar por empresa, correo o teléfono"
            type="text"
            name="search"
            onChange={debouncedChangeHandler}
          />
        </InputSearch>
      </SearchAddContainer>
      <Table
        pageCount={pageCount}
        fetchData={fetchData}
        loading={loading}
        data={data}
        columns={columns}
        isPaginated
      />
    </>
  );
};

export default BillingsTable;
