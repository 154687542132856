import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
} from "@holalink/react-ui-components";
import { format, parseISO } from "date-fns";
import BillingService from "../services/BillingService";
import styled from "@emotion/styled";
import theme from "../../commons/theme";

const StyledTableRow = styled.tr`
  td {
    vertical-align: middle;
  }
`;

const TablePayment = styled.table`
  width: 100%;
  color: black;
  tr {
    th,
    td {
      padding: 0.5rem 2rem;
      text-align: left;
    }
  }
`;

const Container = styled.div`
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const AdditionalPaymentTable = ({ additionalPayments, closeModal }) => {
  return (
    <Container>
      {additionalPayments.length > 0 ? (
        <TablePayment>
          <thead>
            <tr>
              <th>Fecha de registro</th>
              <th>Cantidad Tarjetas</th>
              <th>Precio total</th>
              <th>Duración en días</th>
            </tr>
          </thead>
          <tbody>
            {additionalPayments.map((payment) => (
              <StyledTableRow key={payment._id}>
                <td>{format(parseISO(payment.createdAt), "dd/MM/yyyy")}</td>
                <td>{payment.quantity}</td>
                <td>
                  {payment.currency}
                  {parseFloat(payment.totalAmount).toFixed(2)}
                </td>
                <td>{payment.duration}</td>
              </StyledTableRow>
            ))}
          </tbody>
        </TablePayment>
      ) : (
        <Typography>No hay pagos adicionales</Typography>
      )}
    </Container>
  );
};
