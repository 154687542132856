import { PageLoader } from "@holalink/react-ui-components";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routing/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
function App() {
  const pageLoading = useSelector((state) => state.common.pageLoading);
  return (
    <div className="App">
      <BrowserRouter>
        {pageLoading && <PageLoader />}
        <AppRoutes />
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
