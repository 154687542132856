import { useState, useMemo, useEffect } from "react";
import CompanyService from "../../companies/services/CompanyService";

export const useCompany = ({ companyType }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);


  const companyOptions = useMemo(() => {
    return companies.map((company) => {
      return {
        label: company.name,
        value: company.id,
      };
    });
  }, [companies]);

  useEffect(() => {
    const fetchCompanies = () => {
      setLoading(true);
      CompanyService.getAll({ companyType })
        .then((response) => {
          setCompanies(response.data);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    };
    fetchCompanies();
  }, []);

  return {
    companies,
    companyOptions,
    loading,
  };
};
