import styled from "@emotion/styled";
import { Flex } from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import CompanyService from "../../companies/services/CompanyService";
import Select from "react-select";
import theme from "../../commons/theme";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";
import chroma from "chroma-js";
import DefaultSelect from "../../commons/components/DefaultSelect";
import { css } from "@emotion/react";
import { useMemo } from "react";

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${theme.colors.alto}`,
    borderRadius: "10px",
    transition: "border-color 0.3s ease",
    "&:hover": {
      borderColor: theme.colors.alto,
    },
    "&:focus": {
      borderColor: theme.colors.azureRadiance,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? theme.colors.azureRadiance
      : state.isFocused
      ? theme.colors.alto
      : "white",
    color: state.isSelected
      ? "white"
      : state.isFocused
      ? theme.colors.blackOlive
      : theme.colors.blackOlive,
    "&:hover": {
      backgroundColor: state.isSelected
        ? theme.colors.azureRadiance
        : state.isFocused
        ? theme.colors.alto
        : "white",
      color: state.isSelected
        ? "white"
        : state.isFocused
        ? theme.colors.blackOlive
        : theme.colors.blackOlive,
    },
  }),
  multiValue: (provided, state) => {
    const color = chroma(theme.colors.azureRadiance);
    return {
      ...provided,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: theme.colors.azureRadiance,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: theme.colors.azureRadiance,
    ":hover": {
      backgroundColor: theme.colors.azureRadiance,
      color: "white",
    },
  }),
};

const NameContainer = styled(Flex)`
  gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
  > div {
    flex: 50%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const PhoneEmailContainer = styled(Flex)`
  gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
  > div {
    flex: 50%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const CompanyContainer = styled(Flex)`
  margin-bottom: 20px;
  .select-search {
    width: 100%;
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
  .select-search > div:first-of-type:hover {
    border-color: ${theme.colors.alto};
  }
`;

const RolContainer = styled(Flex)`
  margin-bottom: 20px;
  .select-search {
    width: 100%;
    font-size: 1rem;
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
  .select-search > div:first-of-type:hover {
    border-color: ${theme.colors.alto};
  }
`;

const ContentForm = styled.div`
  width: 100%;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${(props) => props.error !== "" && InputError}
`;

const ErrorLabel = styled.span`
  font-size: 0.8rem;
  color: ${theme.colors.pastelRed};
  margin-top: 5px;
  display: block;
`;

const validations = {
  name: {
    validate: (name) => {
      if (!name) {
        return "El nombre es requerido";
      }
      if (name.trim() === "" || !/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(name)) {
        return "El nombre debe contener letras";
      } else if (name.length < 3) {
        return "El nombre debe tener al menos 3 caracteres";
      }
      return "";
    },
  },
  lastname: {
    validate: (lastname) => {
      if (!lastname) {
        return "El apellido es requerido";
      }
      if (lastname.trim() === "" || !/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(lastname)) {
        return "El apellido debe contener letras";
      } else if (lastname.length < 3) {
        return "El apellido debe tener al menos 3 caracteres";
      }
      return "";
    },
  },
  phone: {
    validate: (phone) => {
      if (!phone) {
        return "El teléfono es requerido";
      }
      if (phone.trim() === "" || !/^\+\d{10,11}$/.test(phone)) {
        return "El teléfono debe ser válido";
      } else if (phone.length < 9) {
        return "El teléfono debe tener al menos 9 números";
      }
      return "";
    },
  },
  email: {
    validate: (email) => {
      if (!email) {
        return "El email es requerido";
      }
      if (
        email.trim() === "" ||
        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
      ) {
        return "El email debe ser válido";
      }
      return "";
    },
    phone: (phone) => phone.trim() === "" || phone.length !== 9,
    company: (company) => company === "",
    role: (role) => role === "",
  },
  company: {
    validate: (company) => {
      if (!company) {
        return "La compañía es requerida";
      }
      return "";
    },
  },
  role: {
    validate: (role) => {
      if (!role) {
        return "El rol es requerido";
      }
      return "";
    },
  },
};

const EmployeeForm = ({ data, onChange, accountType }) => {
  const [formValue, setFormValue] = useState(data || {});
  const [companies, setCompanies] = useState([]);
  const [errors, setErrors] = useState({
    name: "",
    lastname: "",
    phone: "",
    email: "",
    company: "",
    role: "",
  });

  const validateField = (name, value) => {
    setErrors((prev) => ({
      ...prev,
      [name]: validations[name].validate(value),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const loadCompanies = () => {
    CompanyService.getAll({ companyType: accountType }).then((response) => {
      setCompanies(response.data);
    });
  };

  useEffect(() => {
    if (!data) return;
    setData(data);
  }, [data]);

  useEffect(() => {
    loadCompanies();

  }, []);

  useEffect(() => {
    onChange(formValue, errors);
  }, [formValue, errors, onChange]);

  const setData = (initialFormData) => {
    setFormValue({
      name: initialFormData.name,
      lastname: initialFormData.lastname,
      phone: initialFormData.phone,
      email: initialFormData.email,
      company: initialFormData.company,
      role: initialFormData.role,
    });
  };

  const optionsCompany = useMemo(() => {
    return companies.map((company) => ({
      value: company.id,
      label: company.name,
    }));
  }, [companies]);

  const optionsRole = [
    { value: "ADMIN_FOUNDER", label: "Administrador Fundador" },
    { value: "ADMIN", label: "Administrador" },
    { value: "EDITOR", label: "Editor" },
    { value: "EMPLOYEE", label: "Empleado" },
  ];

  const handleRoleValue = (value) => {
    if (!value) return "";
    const role = optionsRole.find((role) => role.value === value);
    return role.value;
  };

  return (
    <Flex flexDirection="column">
      <NameContainer>
        <ContentForm>
          <label>Nombre*</label>
          <InputForm
            name="name"
            placeholder="Nombre"
            defaultValue={formValue.name}
            onChange={handleChange}
            type="text"
            error={errors?.name}
          />
          {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </ContentForm>
        <ContentForm>
          <label>Apellidos*</label>
          <InputForm
            name="lastname"
            placeholder="Apellidos"
            defaultValue={formValue.lastname}
            onChange={handleChange}
            type="text"
            error={errors?.lastname}
          />
          {errors?.lastname && <ErrorLabel>{errors.lastname}</ErrorLabel>}
        </ContentForm>
      </NameContainer>
      <PhoneEmailContainer>
        <ContentForm>
          <label>Teléfono</label>
          <InputForm
            name="phone"
            maxLength={12}
            placeholder="Teléfono"
            defaultValue={formValue.phone}
            onChange={handleChange}
            type="text"
            error={errors?.phone}
          />
          {errors?.phone && <ErrorLabel>{errors.phone}</ErrorLabel>}
        </ContentForm>
        <ContentForm>
          <label>Email*</label>
          <InputForm
            name="email"
            placeholder="Email"
            defaultValue={formValue.email}
            onChange={handleChange}
            type="email"
            error={errors?.email}
          />
          {errors?.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </ContentForm>
      </PhoneEmailContainer>
      <CompanyContainer>
        <ContentForm>
          <label>Empresa*</label>
          <Select
            name="company"
            placeholder="Buscar"
            isSearchable
            isDisabled={accountType === ACCOUNT_TYPES.PERSONAL}
            options={optionsCompany}
            className="select-search"
            styles={customStyles}
            value={optionsCompany.find(
              (option) => option.value === formValue.company
            )}
            onChange={(e) => {
              setFormValue((prevState) => ({
                ...prevState,
                company: e.value,
              }));
            }}
            error={errors?.company}
          />
          {errors?.company && <ErrorLabel>{errors.company}</ErrorLabel>}
        </ContentForm>
      </CompanyContainer>
      <ContentForm>
        <label>Rol*</label>
        <RolContainer>
          <DefaultSelect
            name="role"
            placeholder="Seleccionar rol"
            options={optionsRole}
            className="select-search"
            value={handleRoleValue(formValue.role)}
            onChange={(e) => {
              setFormValue((prevState) => ({
                ...prevState,
                role: e.target.value,
              }));
            }}
            error={errors?.role}
          />
          {errors?.role && <ErrorLabel>{errors.role}</ErrorLabel>}
        </RolContainer>
      </ContentForm>
    </Flex>
  );
};

export default EmployeeForm;
