import { LOGOUT, SET_AUTH_USER } from "../action-types/authActionTypes";

const initialState = {
  user: null,
  token: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      const auth = action.payload;
      return auth;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
