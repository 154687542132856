import React, { useState } from "react";
import "../css/UserDropdown.css";

const UserDropdown = ({ name, email, logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenOptions = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="avatar-wrapper" onClick={handleOpenOptions}>
        <i className="ri-user-2-fill"></i>
      </div>
      <div className={isOpen ? "dropdown-wrapper active" : "dropdown-wrapper"}>
        <div className="dropdown-profile-details">
          <span className="dropdown-name">{name}</span>
          <span className="dropdown-email">{email}</span>
        </div>
        <ul className="dropdown-links">
          <li onClick={logout}>Cerrar Sesión</li>
        </ul>
      </div>
    </div>
  );
};

UserDropdown.defaultProps = {
  name: "",
  email: "",
};

export default UserDropdown;
