import {
  Alert,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNavbarTitle } from "../../store/common/actions/commonActions";
import CompaniesTable from "../components/CompaniesTable";
import CompanyForm from "../components/CompanyForm";
import CompanyService from "../services/CompanyService";
import styled from "@emotion/styled";
import theme from "../../commons/theme";
import DeactivationForm from "../components/DeactivationForm";
import { ACCOUNT_TYPES } from "../../commons/constants/employee";

const TextInformation = styled.p`
  font-size: 1rem;
  color: ${theme.colors.blackOlive};
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export function CompaniesList() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
  const [isOverdueMessage, setIsOverdueMessage] = useState(false);
  const [companyFormValue, setCompanyFormValue] = useState({});
  const [deactivationFormValue, setDeactivationFormValue] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [companyToRestore, setCompanyToRestore] = useState(null);
  const [reloadCompanies, setReloadCompanies] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleCompanyFormChange = (formValue, errors) => {
    setCompanyFormValue(formValue);
    setIsValidForm(validateForm(formValue, errors));
  };

  useEffect(() => {
    dispatch(setNavbarTitle("Tarjetas HolaLink - Empresas"));
    if (isAddModalOpen || isEditModalOpen || isDeleteModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [dispatch, isAddModalOpen, isEditModalOpen, isDeleteModalOpen]);

  const handleSave = () => {
    if (!isValidForm) return;
    CompanyService.save(companyFormValue)
      .then((response) => {
        setReloadCompanies(true);
        setIsAddModalOpen(false);
        setModalErrorMessage("");
      })
      .catch(({ response }) => {
        const error = response.data;
        setModalErrorMessage(error ? error.message : "Ocurrió un error");
        setTimeout(() => {
          setModalErrorMessage("");
        }, 10000);
      });
  };

  const handleEdit = () => {
    if (!isValidForm || !companyToEdit) return;
    CompanyService.edit(companyToEdit.id, companyFormValue)
      .then((response) => {
        setReloadCompanies(true);
        setCompanyToEdit(null);
        setIsEditModalOpen(false);
        setModalErrorMessage("");
      })
      .catch(({ response }) => {
        const error = response.data;
        setModalErrorMessage(error ? error.message : "Ocurrió un error");
        setTimeout(() => {
          setModalErrorMessage("");
        }, 10000);
      });
  };

  const handleOpenEditModal = (companyId) => {
    setModalErrorMessage("");
    if (!companyId) return;
    CompanyService.getById(companyId)
      .then((response) => {
        setCompanyToEdit(response.data);
        setIsEditModalOpen(true);
      })
      .catch((err) => {
        setCompanyToEdit(null);
        setIsEditModalOpen(false);
      });
  };

  const handleOpenAddModal = () => {
    setModalErrorMessage("");
    setIsAddModalOpen(true);
  };

  const handleDelete = () => {
    if (!companyToDelete || !deactivationFormValue) return;
    if (!deactivationFormValue.comments) {
      setErrorMessage("Ingrese un comentario");
      setTimeout(() => {
        setErrorMessage("");
      }, 8000);
      return;
    }
    if (companyToDelete.isOverdue) {
      setIsOverdueMessage(true);
      return;
    } else {
      CompanyService.delete(
        companyToDelete.id,
        deactivationFormValue.comments,
        deactivationFormValue.fileUrl
      )
        .then((response) => {
          setReloadCompanies(true);
          setIsDeleteModalOpen(false);
        })
        .catch(({ response }) => {
          setCompanyToDelete(null);
          setIsDeleteModalOpen(true);
          setErrorMessage(
            response.data.message ||
              "Ocurrió un error. Consulte con el Administrador."
          );
          setTimeout(() => {
            setErrorMessage("");
          }, 8000);
        })
        .finally(() => {});
    }
  };

  const handleOpenDeleteModal = (companyId, isOverdue) => {
    if (!companyId) return;
    CompanyService.getById(companyId)
      .then((response) => {
        setCompanyToDelete(Object.assign(response.data, { isOverdue }));
        setIsDeleteModalOpen(true);
      })
      .catch((err) => {
        setCompanyToDelete(null);
        setIsDeleteModalOpen(false);
      });
  };

  const handleRestore = () => {
    if (!companyToRestore) return;
    CompanyService.restore(companyToRestore.id)
      .then((response) => {
        setReloadCompanies(true);
      })
      .catch(({ response }) => {
        setErrorMessage(
          response.data.message ||
            "Ocurrió un error. Consulte con el Administrador."
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 15000);
      })
      .finally(() => {
        setCompanyToRestore(null);
        setIsRestoreModalOpen(false);
      });
  };

  const handleOpenRestoreModal = (companyId) => {
    if (!companyId) return;

    CompanyService.getById(companyId)
      .then((response) => {
        setCompanyToRestore(response.data);
        setIsRestoreModalOpen(true);
      })
      .catch((err) => {
        setCompanyToRestore(null);
        setIsRestoreModalOpen(false);
      });
  };

  const handleDeactivationFormChange = (value) => {
    setDeactivationFormValue(value);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setIsOverdueMessage(false);
  };


  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const validateForm = (formValue, errors) => {
    const {
      name,
      address,
      phone,
      ruc,
      initialBillingCycle,
      mainEmail,
      webUrl,
      plan,
    } = formValue;

    if (
      !companyToEdit ||
      companyToEdit.companyType !== ACCOUNT_TYPES.PERSONAL
    ) {
      const hasEmptyValues =
        !name ||
        !address ||
        !phone ||
        !ruc ||
        !initialBillingCycle ||
        !mainEmail ||
        !webUrl ||
        !plan;

      if (hasEmptyValues) return false;

      const isValidBillingCycle = (billingCycle) => {
        const date = new Date(billingCycle);
        const day = date.addDays(1).getDate();
        if (day > 28) return false;
        return true;
      };

      const isValidBillingCycleValue = isValidBillingCycle(initialBillingCycle);

      if (!isValidBillingCycleValue) return false;
    } else {
      const hasEmptyValues = !name || !phone || !mainEmail;

      if (hasEmptyValues) return false;
    }

    const hasError = Object.values(errors).some((value) => !!value);
    if (hasError) {
      return false;
    }

    return true;
  };

  return (
    <>
      <CompaniesTable
        openAddModal={handleOpenAddModal}
        openEditModal={handleOpenEditModal}
        openDeleteModal={handleOpenDeleteModal}
        openRestoreModal={handleOpenRestoreModal}
        reload={reloadCompanies}
        setReloadCompanies={setReloadCompanies}
      />
      {isAddModalOpen && (
        <Modal setIsOpen={setIsAddModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsAddModalOpen}>Crear Empresa</ModalHeader>
          <ModalContent>
            <CompanyForm onChange={handleCompanyFormChange} />
            {modalErrorMessage && (
              <Alert type="error">{modalErrorMessage}</Alert>
            )}
          </ModalContent>
          <ModalFooter>
            <Button
              variant={!isValidForm ? "disabled" : "primary"}
              size="small"
              onClick={handleSave}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal setIsOpen={setIsEditModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsEditModalOpen}>
            Editar Empresa
          </ModalHeader>
          <ModalContent>
            <CompanyForm
              data={companyToEdit}
              onChange={handleCompanyFormChange}
            />
            {modalErrorMessage && (
              <Alert type="error">{modalErrorMessage}</Alert>
            )}
          </ModalContent>
          <ModalFooter>
            <Button
              variant={!isValidForm ? "disabled" : "primary"}
              size="small"
              onClick={handleEdit}
            >
              Editar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal setIsOpen={setIsDeleteModalOpen} size="small">
          <ModalHeader setIsOpen={setIsDeleteModalOpen}>
            Desactivar Empresa
          </ModalHeader>
          <ModalContent>
            <TextInformation>
              ¿Estás seguro que deseas desactivar la empresa{" "}
              <strong>{companyToDelete && companyToDelete.name}</strong>?
            </TextInformation>
            <DeactivationForm onChange={handleDeactivationFormChange} />
          </ModalContent>
          <ModalFooter>
            <Button variant="primary" size="small" onClick={handleDelete}>
              Desactivar
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => handleCloseDeleteModal()}
            >
              Cancelar
            </Button>
          </ModalFooter>
          {errorMessage && <Alert type="error">{errorMessage}</Alert>}
          {isOverdueMessage && (
            <div className="alert alert-warning" role="alert">
              La compañía cuenta con cuentas por cobrar. Por favor asegurarse de
              tener las cuentas pagadas antes de desactivar la compañía.
            </div>
          )}
        </Modal>
      )}
      {isRestoreModalOpen && (
        <Modal setIsOpen={setIsRestoreModalOpen} size="small">
          <ModalHeader setIsOpen={setIsRestoreModalOpen}>
            Restaurar Empresa
          </ModalHeader>
          <ModalContent>
            <TextInformation>
              ¿Estás seguro que deseas volver a activar la empresa{" "}
              <strong>{companyToRestore.name}</strong>?
            </TextInformation>
          </ModalContent>
          <ModalFooter>
            <Button variant="primary" size="small" onClick={handleRestore}>
              Restaurar
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => setIsRestoreModalOpen(false)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
