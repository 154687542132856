import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import styled from "@emotion/styled";
import { Button, Flex, Table } from "@holalink/react-ui-components";

import BusinessCardService from "../services/BusinessCardService";
import { format } from "date-fns";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ButtonContainer = styled.div`
  margin: auto 0;
`;

const SearchAddContainer = styled(Flex)`
  margin-bottom: 20px;
  gap: 20px;
`;

const InputSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  input {
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 1rem;
    &:focus-visible {
      outline: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const BusinessCardTable = ({ onDownloadReport }) => {
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const columns = useMemo(
    () => [
      {
        accessor: "counter",
        Header: "ID",
        width: "60",
        Cell: ({ value }) => {
          if (value && value > 0) {
            return <CounterStyled>#{value}</CounterStyled>;
          } else {
            return <CounterStyled>{"-"}</CounterStyled>;
          }
        },
      },
      {
        accessor: "names",
        Header: "Nombres",
      },
      {
        accessor: "email",
        Header: "Correo",
        width: "200",
      },
      {
        accessor: "company",
        Header: "Empresa",
      },
      {
        accessor: "type",
        Header: "Tipo",
      },
      {
        accessor: "createdAt",
        Header: "Fecha Creación",
        width: "200",
        Cell: ({ value }) => {
          return value ? format(new Date(value), "dd/MM/yyyy hh:mm:ss") : "-";
        },
      },
      {
        accessor: "createdBy",
        Header: "Creado Por",
        width: "200",
        Cell: ({ value }) => value || "-",
      },
      {
        accessor: "updatedAt",
        Header: "Fecha Actualización",
        width: "200",
        Cell: ({ value }) => {
          return value ? format(new Date(value), "dd/MM/yyyy hh:mm:ss") : "-";
        },
      },
      {
        accessor: "updatedBy",
        Header: "Actualizado Por",
        width: "200",
        Cell: ({ value }) => value || "-",
      },
    ],
    []
  );

  const loadBusinessCards = ({ limit, skip, search }) => {
    setLoading(true);
    BusinessCardService.search(limit, skip, search)
      .then((response) => {
        setData(response.data.data);
        setPageCount(response.data.paging.pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadBusinessCards({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search,
      });
    },
    [search]
  );

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };

  const handleDownloadReport = () => {
    onDownloadReport(search);
  };

  useEffect(() => {
    fetchData({ pageSize: 10, pageIndex: 0 });
  }, [fetchData]);

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), []);

  return (
    <>
      <SearchAddContainer>
        <InputSearch>
          <i className="ri-search-line" />
          <input
            placeholder="Buscar por nombres o correo"
            type="text"
            name="search"
            onChange={debouncedChangeHandler}
          />
        </InputSearch>
        <ButtonContainer>
          <Button
            variant="primary"
            size="medium"
            onClick={handleDownloadReport}
          >
            <span className="p-2">Descargar</span>
            <i className="ri-download-line" />
          </Button>
        </ButtonContainer>
      </SearchAddContainer>
      <Table
        pageCount={pageCount}
        fetchData={fetchData}
        loading={loading}
        data={data}
        columns={columns}
        isPaginated
      />
    </>
  );
};

export default BusinessCardTable;
