import styled from "@emotion/styled";
import { Theme } from "@holalink/react-ui-components";

const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 8px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${Theme.colors.azureRadiance} transparent transparent;
  }
  > div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  > div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  > div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingContainer>
  );
};

export default Loading;
