import axiosInstance from "../../commons/helpers/axios";

export const MonitoringService = {
  getVisitsByEmployee: async (companyId, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/top-users`,
        {
          params: {
            filter,
          },
        }
      );
    } catch (error) {
      return error.response;
    }
  },
  getEventsByCategory: async (companyId, category, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/category`,
        {
          params: {
            name: category,
            filter,
          },
        }
      );
    } catch (error) {
      return error.response;
    }
  },
  getVisitsWithRatingSummary: async (companyId, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/rating-information`,
        {
          params: {
            filter,
          },
        }
      );
    } catch (error) {
      return error.response && error.response;
    }
  },
  downloadReport: (companyId, format, filter) => {
    return axiosInstance.get(
      `/statistics/general/company/${companyId}/report`,
      {
        params: {
          filter,
          format,
        },
        responseType: "text",
      }
    );
  },
};
