import styled from '@emotion/styled';
import { Button, Flex, Typography } from '@holalink/react-ui-components';
import React, { useEffect, useState } from 'react'
import { useForm } from '../../commons/hooks/useForm';
import MetadataService from '../services/MetadataService';
import Loading from '../../commons/components/Loading';
import theme from '../../commons/theme';
import { css } from '@emotion/react';
import { setNavbarTitle } from '../../store/common/actions/commonActions';
import { useDispatch } from 'react-redux';

export const InputForm = styled.input`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${theme.colors.alto};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: border-color 0.3s ease;
  ::placeholder {
    color: ${theme.colors.alto};
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  ${(props) => props.error !== "" && InputError}
`;


const ErrorLabel = styled.span`
  color: ${theme.colors.pastelRed};
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
`;

const InputError = css`
  border-color: ${theme.colors.pastelRed};
  &:focus {
    border-color: ${theme.colors.pastelRed};
  }
`;

const ContentForm = styled.div`
  width: 100%;
  margin: 12px 0;
  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: ${theme.colors.blackOlive};
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const initialFormValues = {
  cutoffDay: 0,
};

const initialErrors = {
  cutoffDay: '',
};

const validations = {
  cutoffDay: {
    validate: (value) => {
      if (!value) {
        return "El día de corte es requerido";
      }
      if (value < 1 || value > 28) {
        return "El día de corte debe ser un día entre 1 y 28";
      }
      return "";
    },
  }

}

export const ConfigurationView = () => {
  const { formValues, errors, setFormValues, handleChange, validate } = useForm({
    initialFormValues,
    initialErrors,
    validations
  });
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const dispatch = useDispatch();

  const editMetadata = () => {
    const isValid = validate();
    if (!isValid) return;
    setLoadingSubmit(true)
    MetadataService.edit(formValues).then(response => {
      console.log(response);
    })
      .catch(error => console.log(error))
      .finally(() => setLoadingSubmit(false));
  }

  useEffect(() => {
    dispatch(setNavbarTitle("Tarjetas HolaLink - Configuración"));

    const getMetadata = async () => {
      setLoading(true)
      MetadataService.getMetadata().then(response => {
        const { cutoffDay } = response.data;
        setFormValues({
          cutoffDay: cutoffDay || 0
        });
      })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));
    };
    getMetadata();
  }, [dispatch])

  return (
    <Flex flexDirection="column" style={{ maxWidth: '300px' }}>
      {loading && <Loading />}
      {!loading && <ContentForm>
        <label htmlFor="cutoffDay">Día de corte</label>
        <InputForm
          name='cutoffDay'
          type='number'
          value={formValues.cutoffDay}
          placeholder='Día de corte'
          onChange={handleChange}
          error={errors?.cutoffDay}
        />
        <ErrorLabel>{errors?.cutoffDay}</ErrorLabel>
      </ContentForm>}
      <Button
        variant='primary'
        size='small'
        onClick={editMetadata}
      >
        Guardar
      </Button>
      {loadingSubmit && (
        <Typography variant='small'>Cargando...</Typography>
      )}
    </Flex>
  );
}
