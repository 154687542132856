import styled from "@emotion/styled";
import { Button, Flex, Table, Badge } from "@holalink/react-ui-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import CompanyService from "../services/CompanyService";
import theme from "../../commons/theme";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 auto;
`;

const CompanyStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    height: 30px;
    border-radius: 10px;
  }
`;

const InactiveStatus = styled.span`
  color: ${theme.colors.carnation};
`;

const ButtonContainer = styled.div`
  margin: auto 0;
`;

const SearchAddContainer = styled(Flex)`
  margin-bottom: 20px;
  gap: 20px;
`;

const InputSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  input {
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 1rem;
    &:focus-visible {
      outline: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .ri-edit-box-line {
    color: ${theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-delete-bin-line {
    color: ${theme.colors.pastelRed};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-refresh-line {
    color: ${theme.colors.springGreenHover};
    font-size: 20px;
    cursor: pointer;
  }
  .ri-line-chart-fill {
    color: ${theme.colors.azureRadiance};
    font-size: 20px;
    cursor: pointer;
  }
`;

const CompaniesTable = ({
  openAddModal,
  openEditModal,
  openDeleteModal,
  openRestoreModal,
  reload,
  setReloadCompanies,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const goToStatistics = (companyId) => {
    if (!companyId) return;
    navigate(`/statistics/${companyId}`);
  };

  const columns = useMemo(
    () => [
      {
        accessor: "counter",
        Header: "ID",
        width: "60",
        Cell: ({ value }) => {
          if (value && value > 0) {
            return <CounterStyled>#{value}</CounterStyled>;
          } else {
            return <CounterStyled>{"-"}</CounterStyled>;
          }
        },
      },
      {
        id: "name",
        accessor: (row) => ({ logoUrl: row.logoUrl, name: row.name }),
        Header: "Empresa",
        width: "200",
        Cell: ({ value }) => {
          return value ? (
            <CompanyStyled>
              {value.logoUrl && <img src={value.logoUrl} alt={value.name} />}
              {value.name}
            </CompanyStyled>
          ) : (
            ""
          );
        },
      },
      {
        accessor: "createdAt",
        Header: "Fecha de Registro",
        width: "200",
        Cell: ({ value }) => {
          return value ? format(new Date(value), "dd/MM/yyyy") : "";
        },
      },
      {
        accessor: "admin",
        Header: "Administrador",
        width: "300",
      },
      {
        accessor: "mainEmail",
        Header: "Correo",
        width: "250",
      },
      {
        accessor: "phone",
        Header: "Teléfono",
        width: "140",
      },
      {
        accessor: "address",
        Header: "Dirección",
        width: "250",
      },
      {
        id: "quota",
        accessor: (row) => ({
          maxCards: (row.plan && row.plan.maxCards) || "-",
          quantity: row.quantity,
        }),
        Header: "Tarjetas",
        width: "150",
        Cell: ({ value }) => {
          return `${value.quantity}/${value.maxCards}`;
        },
      },
      {
        id: "planName",
        accessor: (row) => row.plan || {},
        Header: "Paquete",
        width: "200",
        Cell: ({ value }) => {
          return (
            <Badge color={value.color}>{value.name || "Sin paquete"}</Badge>
          );
        },
      },
      {
        accessor: "unpaid",
        Header: "Falta pagar",
        width: "250",
        //hide
        Cell: ({ value }) => {
          return value ? "Si" : "No";
        },
      },
      {
        accessor: "disabled",
        Header: "Estado",
        width: "200",
        Cell: ({ value }) => {
          return value ? <InactiveStatus>Inactivo</InactiveStatus> : "Activo";
        },
      },
      {
        id: "actions",
        accessor: (row) => ({ id: row.id, disabled: row.disabled }),
        Header: "Acción",
        width: "120",
        Cell: (cellProps) => {
          const values = cellProps.cell.row.values;
          return (
            <ActionContainer>
              <i
                className="ri-edit-box-line"
                onClick={() => openEditModal(cellProps.value.id)}
              ></i>
              {cellProps.value.disabled ? (
                <i
                  className="ri-refresh-line"
                  onClick={() => openRestoreModal(cellProps.value.id)}
                ></i>
              ) : (
                <i
                  className="ri-delete-bin-line"
                  onClick={() => {
                    openDeleteModal(cellProps.value.id, values.unpaid);
                  }}
                ></i>
              )}
              <i
                className="ri-line-chart-fill"
                onClick={() => goToStatistics(cellProps.value.id)}
              ></i>
            </ActionContainer>
          );
        },
      },
    ],
    [openEditModal, openDeleteModal, openRestoreModal]
  );

  const loadCompanies = ({ limit, skip, search }) => {
    setLoading(true);
    CompanyService.search(limit, skip, search)
      .then((response) => {
        const companiesData = response.data.data;
        setPageCount(response.data.paging.pages);
        setData(companiesData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadCompanies({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search,
      });
    },
    [search]
  );

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };

  useEffect(() => {
    if (reload) {
      setReloadCompanies(false);
    }
  }, [reload, setReloadCompanies]);

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), []);

  return (
    <>
      <SearchAddContainer>
        <InputSearch>
          <i className="ri-search-line" />
          <input
            placeholder="Buscar por empresa, correo o teléfono"
            type="text"
            name="search"
            onChange={debouncedChangeHandler}
          />
        </InputSearch>
        <ButtonContainer>
          <Button
            variant="primary"
            size="medium"
            onClick={() => openAddModal()}
          >
            <i className="ri-add-line" />
            <i className="ri-user-3-line" />
          </Button>
        </ButtonContainer>
      </SearchAddContainer>
      {!reload && (
        <Table
          pageCount={pageCount}
          fetchData={fetchData}
          loading={loading}
          data={data}
          columns={columns}
          isPaginated
        />
      )}
    </>
  );
};

export default CompaniesTable;
