import styled from "@emotion/styled";
import { Flex, NavigationDrawer } from "@holalink/react-ui-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/actions/authActions";
import theme from "../theme";
import { setIsDrawerOpen } from "../../store/common/actions/commonActions";
import { TopNavBar } from "./TopNavBar";

const PanelContent = styled.div`
  width: -webkit-fill-available;
  max-width: 100%;
  width: -moz-available;
  margin-left: ${(props) => (props.isDrawerOpen ? "260px" : "80px")};
  transition: margin-left 0.5s;
  width: ${(props) =>
    props.isDrawerOpen ? "calc(100% - 260px)" : "calc(100% - 80px)"};
  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const PageContainer = styled.div`
  padding: 2.5rem 1.5rem;
  display: flex;
  height: 100%;
  background-color: ${theme.colors.whiteLilac};
  @media screen and (max-width: 768px) {
    padding: calc(-5px + 1.5rem) calc(1.5rem * 0.5) calc(-5px + 1.5rem)
      calc(1.5rem * 0.5);
    max-width: 100%;
  }
`;

const MainContent = styled.div`
  width: -webkit-fill-available;
  width: -moz-available;
  width: 100%;
`;

const menuItems = [
  {
    path: "employees",
    label: "Usuarios coporativos",
    icon: "ri-user-star-line",
  },
  {
    path: "personal-employees",
    label: "Usuarios personales",
    icon: "ri-user-line",
  },
  {
    path: "business-cards",
    label: "Tarjetas",
    icon: "ri-bank-card-line",
  },
  {
    path: "companies",
    label: "Empresas",
    icon: "ri-building-2-line",
  },
  {
    path: "billings",
    label: "Facturac. corporativa",
    icon: "ri-money-dollar-circle-line",
  },
  {
    path: "personal-billings",
    label: "Facturación personal",
    icon: "ri-money-dollar-circle-line",
  },
  {
    path: "precios",
    label: "Precios",
    icon: "ri-price-tag-3-fill",
  },
  {
    path: "additional-payments",
    label: "Pagos adicionales",
    icon: "ri-money-dollar-circle-line",
  },
  {
    path: "configuration",
    label: "Configuración",
    icon: "ri-settings-2-line",
  }
];

export default function Layout() {
  const { isDrawerOpen } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const isAuthenticated = !!useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const navbarTitle = useSelector((state) => state.common.navbarTitle);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("login");
    }
  }, [navigate, isAuthenticated]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleOpenDrawer = (value) => {
    dispatch(setIsDrawerOpen(value));
  };

  return (
    <Flex>
      <NavigationDrawer
        onChangeOpen={handleOpenDrawer}
        isOpen={isDrawerOpen}
        menuItems={menuItems}
        logoMini={"/logo-mini.png"}
        logo={"/logo.png"}
      />
      <PanelContent isDrawerOpen={isDrawerOpen}>
        <TopNavBar
          user={user && user.email}
          title={navbarTitle}
          onLogout={handleLogout}
          onToggleSidebar={handleOpenDrawer}
        />
        <PageContainer isDrawerOpen={isDrawerOpen}>
          <MainContent>
            <Outlet />
          </MainContent>
        </PageContainer>
      </PanelContent>
    </Flex>
  );
}
