import {
  Alert,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@holalink/react-ui-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNavbarTitle } from "../../store/common/actions/commonActions";
import PricingForm from "../components/PricingForm";
import PricingTable from "../components/PricingTable";
import PricingService from "../services/PricingService";
import styled from "@emotion/styled";
import theme from "../../commons/theme";

const TextInformation = styled.p`
  font-size: 1rem;
  color: ${theme.colors.blackOlive};
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export function PricingsList() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [pricingFormValue, setPricingFormValue] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [pricingToEdit, setPricingToEdit] = useState(null);
  const [pricingToDelete, setPricingToDelete] = useState(null);
  const [reloadPricings, setReloadPricings] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();

  const handlePricingFormChange = (formValue) => {
    setPricingFormValue(formValue);
    setIsValidForm(validateForm(formValue));
  };

  useEffect(() => {
    dispatch(setNavbarTitle("Tarjetas HolaLink - Precios"));
    if (isAddModalOpen || isEditModalOpen || isDeleteModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [dispatch, isAddModalOpen, isEditModalOpen, isDeleteModalOpen]);

  const getProducts = () => {
    PricingService.getProducts()
      .then((response) => {
        const parsedProducts = response.data.map((item) => ({
          value: item._id,
          label: item.name,
        }));
        setProducts(parsedProducts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = () => {
    if (!isValidForm) return;
    PricingService.save(pricingFormValue)
      .then((response) => {
        setReloadPricings(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsAddModalOpen(false);
      });
  };

  const handleEdit = () => {
    if (!isValidForm || !pricingToEdit) return;
    PricingService.edit(pricingToEdit.id, pricingFormValue)
      .then((response) => {
        setReloadPricings(true);
      })
      .finally(() => {
        setPricingToEdit(null);
        setIsEditModalOpen(false);
      });
  };

  const handleDelete = () => {
    if (!pricingToDelete) return;
    PricingService.delete(pricingToDelete.id)
      .then((response) => {
        setReloadPricings(true);
      })
      .catch(({ response }) => {
        setErrorMessage(
          response.data.message ||
            "Ocurrió un error. Consulte con el Adminsitrador."
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 15000);
      })
      .finally(() => {
        setPricingToDelete(null);
        setIsDeleteModalOpen(false);
      });
  };

  const handleOpenEditModal = (pricingId) => {
    if (!pricingId) return;
    PricingService.getById(pricingId)
      .then((response) => {
        setPricingToEdit(response.data);
        setIsEditModalOpen(true);
      })
      .catch((err) => {
        setPricingToEdit(null);
        setIsEditModalOpen(false);
      });
  };

  const handleOpenDeleteModal = (pricingId) => {
    if (!pricingId) return;

    PricingService.getById(pricingId)
      .then((response) => {
        setPricingToDelete(response.data);
        setIsDeleteModalOpen(true);
      })
      .catch((err) => {
        setPricingToDelete(null);
        setIsDeleteModalOpen(false);
      });
  };

  const validateForm = (formValue) => {
    const { name, duration, price, currency, level, product } = formValue;
    const hasEmptyValues =
      !name || !duration || !price || !currency || !level || !product;

    if (hasEmptyValues) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      <PricingTable
        setIsAddOpen={setIsAddModalOpen}
        openEditModal={handleOpenEditModal}
        openDeleteModal={handleOpenDeleteModal}
        reload={reloadPricings}
        setReloadPricings={setReloadPricings}
      />
      {isAddModalOpen && (
        <Modal setIsOpen={setIsAddModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsAddModalOpen}>Crear Precio</ModalHeader>
          <ModalContent>
            <PricingForm
              products={products}
              onChange={handlePricingFormChange}
            />
          </ModalContent>
          <ModalFooter>
            <Button
              variant={!isValidForm ? "disabled" : "primary"}
              size="small"
              onClick={handleSave}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal setIsOpen={setIsEditModalOpen} size="medium">
          <ModalHeader setIsOpen={setIsEditModalOpen}>
            Editar Precio
          </ModalHeader>
          <ModalContent>
            <PricingForm
              data={pricingToEdit}
              products={products}
              onChange={handlePricingFormChange}
            />
          </ModalContent>
          <ModalFooter>
            <Button
              variant={!isValidForm ? "disabled" : "primary"}
              size="small"
              onClick={handleEdit}
            >
              Editar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal setIsOpen={setIsDeleteModalOpen} size="small">
          <ModalHeader setIsOpen={setIsDeleteModalOpen}>
            Eliminar Precio
          </ModalHeader>
          <ModalContent>
            <TextInformation>
              ¿Estás seguro que deseas eliminar el precio {pricingToDelete.name}
              ?
            </TextInformation>
          </ModalContent>
          <ModalFooter>
            <Button variant="primary" size="small" onClick={handleDelete}>
              Eliminar
            </Button>
            <Button
              variant="cancel"
              size="small"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
