import {
  uploadBytesResumable,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "./firebase";
import { fileStatus } from "../constants/file";
const FileServices = {
  getReference: (type, filename) => {
    let storageRef = {};
    switch (type) {
      case "image/*":
        storageRef = ref(storage, `/images/${filename}`);
        break;
      case "application/pdf":
        storageRef = ref(storage, `/pdf/${filename}`);
        break;
      case "voucher":
        storageRef = ref(storage, `/vouchers/${filename}`);
        break;
      case "deactivation-reasons":
        storageRef = ref(storage, `/deactivation-reasons/${filename}`);
        break;
      default:
        storageRef = ref(storage, `/images/${filename}`);
        break;
    }

    return storageRef;
  },

  uploadFile: async (file, storageRef, setFileUploaded = undefined) => {
    if (setFileUploaded !== undefined) {
      setFileUploaded(fileStatus.UPLOADING);
    }
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        if (setFileUploaded !== undefined && progress === 100) {
          setFileUploaded(fileStatus.COMPLETED);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    await uploadTask;
    let downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    return downloadURL;
  },

  deleteFile: (fileRef) => {
    deleteObject(fileRef)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  },
};

export default FileServices;
