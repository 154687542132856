import axiosInstance from "../../commons/helpers/axios";

const BusinessCardService = {
  search: (limit, skip, search) => {
    return axiosInstance.get("/business-cards/search", {
      params: {
        limit,
        skip,
        search,
      },
    });
  },
  downloadReport: (search) => {
    return axiosInstance.get(`/business-cards/report`, {
      params: {
        search,
      },
      responseType: "text",
    });
  },
};

export default BusinessCardService;
