import axiosInstance from "../../commons/helpers/axios";

const AuthService = {
  login: (data) => {
    return axiosInstance.post("/auth/login", data);
  },
  resetPassword: async (data) => {
    return axiosInstance.post("/auth/reset-password", data);
  },
};

export default AuthService;
