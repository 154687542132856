import { Button, Input, Link, Typography } from "@holalink/react-ui-components";
import { useState } from "react";
import { EMAIL_REGEX } from "../../commons/constants/email";
import AuthService from "../services/AuthService";

const LoginForm = ({
  onForgotPasswordClick,
  onLoading,
  onErrorMessage,
  onSuccessMessage,
  onSuccess,
}) => {
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [loginErrors, setLoginErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLoginForm((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s/g, ""),
    }));
  };

  const handleLoginFormValidation = () => {
    const errors = {};
    let isValid = true;

    if (!loginForm.email) {
      errors.email = "Ingrese un correo";
      isValid = false;
    } else if (!EMAIL_REGEX.test(loginForm.email)) {
      errors.email = "Ingrese un correo válido";
      isValid = false;
    }
    if (!loginForm.password) {
      errors.password = "Ingrese una contraseña";
      isValid = false;
    }
    setLoginErrors(errors);

    return isValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    onErrorMessage("");
    onSuccessMessage("");
    const isValid = handleLoginFormValidation();
    if (!isValid) return;
    onLoading(true);
    AuthService.login(loginForm)
      .then((response) => {
        const { user, token } = response.data;
        onSuccess(user, token);
      })
      .catch((error) => {
        const message = error.response.data.message;
        onErrorMessage(message || "Ocurrió un error");
      })
      .finally(() => {
        onLoading(false);
      });
  };

  return (
    <form onSubmit={handleLogin} method="post" className="mt-3">
      <div className="form-group">
        <Typography weight="bold" variant="small">
          Usuario
        </Typography>
        <Input
          fullWidth
          name="email"
          type="text"
          value={loginForm.email}
          error={!!loginErrors.email}
          onChange={handleChange}
          errorMessage={loginErrors.email}
        />
      </div>
      <div className="form-group">
        <div
          className="hideShowPassword-wrapper"
          style={{
            position: "relative",
            display: "block",
            verticalAlign: "baseline",
            margin: "0px",
          }}
        >
          <Typography weight="bold" variant="small">
            Password
          </Typography>
          <Input
            fullWidth
            name="password"
            type="password"
            value={loginForm.password}
            error={!!loginErrors.password}
            errorMessage={loginErrors.password}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="clearfix mb-3">
        <div className="text-end">
          <Link
            fullWidth={false}
            size="medium"
            variant="primary"
            onClick={onForgotPasswordClick}
          >
            Olvidé mi contraseña
          </Link>
        </div>
      </div>
      <div className="mt-5">
        <Button variant="primary" size="medium" fullWidth={true} type="submit">
          Iniciar sesión
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
