import {
  SET_NAVBAR_TITLE,
  SET_PAGE_LOADING,
  SET_IS_DRAWER_OPEN,
} from "../action-types/commonActionTypes";

const initialState = {
  pageLoading: false,
  navbarTitle: "",
  isDrawerOpen: true,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_LOADING:
      return { ...state, pageLoading: action.payload };
    case SET_NAVBAR_TITLE:
      return { ...state, navbarTitle: action.payload };
    case SET_IS_DRAWER_OPEN:
      return { ...state, isDrawerOpen: action.payload };
    default:
      return state;
  }
};

export default commonReducer;
