import { takeLatest } from "redux-saga/effects";
import { CLEAR_STORE } from "./action-types/commonActionTypes";
import { clearStoreHandler } from "./handlers/commonHandler";

function* handleClearStore() {
  yield takeLatest(CLEAR_STORE, clearStoreHandler);
}

const commonSaga = [handleClearStore];

export default commonSaga;
