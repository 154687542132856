import { LOGOUT, SET_AUTH_USER } from "../action-types/authActionTypes";

export const setAuthUser = ({ token, user }) => {
  return {
    type: SET_AUTH_USER,
    payload: {
      token,
      user,
    },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
