import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Flex } from "@holalink/react-ui-components";

import { MonitoringService } from "../services/MonitoringService";
import TopUsersTable from "../components/TopUsersTable";
import { CATEGORIES, getFieldSchema } from "../../commons/helpers/form.utils";
import TopCTA from "../components/TopCTA";
import TopSocialMedia from "../components/TopSocialMedia";
import BaseFiltersSelector from "../components/BaseFiltersSelector";
import DniRatingTable from "../components/DniRatingTable";
import DownloadReportButton from "../components/DownloadReportButton";
import CompanyService from "../../companies/services/CompanyService";

const THIS_WEEK = "THIS_WEEK";
const CompanyStatisticsContainer = styled.div`
  max-width: 1100px;
  width: 100%;
`;

export function CompanyStatistics() {
  const [currentCompany, setCurrentCompany] = useState({});
  const [hasReferrals, setHasReferrals] = useState(false);
  const [generalContactData, setGeneralContactData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [visitsRatingData, setVisitsRatingData] = useState([]);
  const [socialMediaLoading, setSocialMediaLoading] = useState(false);
  const [generalContactLoading, setGeneralContactLoading] = useState(false);
  const { companyId } = useParams();
  const [filter, setFilter] = useState(THIS_WEEK);

  const fetchCompany = () => {
    CompanyService.getById(companyId).then((response) => {
      setCurrentCompany(response.data);
      setHasReferrals(response.data.features.includes("referrals"));
    });
  };

  const getSocialMediaEvents = () => {
    if (currentCompany && currentCompany.id) {
      setSocialMediaLoading(true);
      MonitoringService.getEventsByCategory(
        currentCompany.id,
        CATEGORIES.SOCIAL_NETWORKS,
        filter
      ).then((res) => {
        if (res.status === 200) {
          const events = res.data.events.map((item) => {
            const fieldSchema = getFieldSchema(item.category, item.key);
            return {
              ...item,
              placeholder: fieldSchema
                ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
                : item.key,
            };
          });

          setSocialMediaData(events);
        } else {
          console.log(res.data.message);
        }
        setSocialMediaLoading(false);
      });
    }
  };

  const getGeneralContactEvents = () => {
    if (currentCompany && currentCompany.id) {
      setGeneralContactLoading(true);
      MonitoringService.getEventsByCategory(
        currentCompany.id,
        CATEGORIES.GENERAL_CONTACTS,
        filter
      ).then((res) => {
        if (res.status === 200) {
          const events = res.data.events.map((item) => {
            const fieldSchema = getFieldSchema(item.category, item.key);
            return {
              ...item,
              placeholder: fieldSchema
                ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
                : item.key,
            };
          });
          setGeneralContactData(events);
        } else {
          console.log(res.data.message);
        }
        setGeneralContactLoading(false);
      });
    }
  };

  const getVisitsWithRatingInformation = () => {
    if (currentCompany && currentCompany.id) {
      setGeneralContactLoading(true);
      MonitoringService.getVisitsWithRatingSummary(currentCompany.id, filter)
        .then((res) => {
          if (res.status === 200 && res.data.visits) {
            setVisitsRatingData(res.data.visits);
          } else {
            console.log(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onChange = (selectedOption) => {
    setFilter(selectedOption);
  };

  useEffect(() => {
    fetchCompany();
  }, [companyId, filter]);

  useEffect(() => {
    getSocialMediaEvents();
    getGeneralContactEvents();
  }, [currentCompany]);

  useEffect(() => {
    if (hasReferrals) {
      getVisitsWithRatingInformation();
    }
  }, [hasReferrals]);

  return (
    <CompanyStatisticsContainer>
      <Flex gap="10px" mb="4">
        <BaseFiltersSelector onChange={onChange} />
        <DownloadReportButton companyId={currentCompany.id} filter={filter} />
      </Flex>
      <TopUsersTable
        filter={filter}
        currentCompany={currentCompany}
      ></TopUsersTable>
      {hasReferrals && (
        <DniRatingTable
          filter={filter}
          type="general"
          data={visitsRatingData}
          tableTitle="Top de referidos"
        />
      )}
      <Flex gap="25px" flexWrap="wrap">
        <TopCTA
          loading={generalContactLoading}
          data={generalContactData}
        ></TopCTA>
        <TopSocialMedia
          loading={socialMediaLoading}
          data={socialMediaData}
        ></TopSocialMedia>
      </Flex>
    </CompanyStatisticsContainer>
  );
}
