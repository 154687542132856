import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Theme } from "@holalink/react-ui-components";
import PropTypes from "prop-types";

const Container = styled.div`
  width: 100%;
`;

const primaryStyle = (props) =>
  css`
    background-color: ${(props.variant === "primary" || !props.variant) &&
    Theme.colors.azureRadiance};
    color: ${(props.variant === "primary" || !props.variant) && "#ffffff"};
  `;
const secondaryStyle = (props) =>
  css`
    background-color: ${props.variant === "secondary" && Theme.colors.gallery};
    color: ${props.variant === "secondary" && "#3d3d3d"};
  `;

const Header = styled.div`
  ${primaryStyle};
  ${secondaryStyle};
  border-radius: 10px 10px 0px 0px;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify && `${props.justify}`};
`;

const HeaderTitle = styled.p`
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const Body = styled.div`
  background-color: #ffffff;
  text-align: ${(props) => props.bodyTextAlign};
  padding: ${(props) => (props.noGutters ? "0px" : "40px")};
  border-radius: 0px 0px 10px 10px;
  @media (max-width: 480px) {
    padding: ${(props) => (props.noGutters ? "0px" : "24px")};
    padding: ${(props) => props.statistic && "5px"};
  }
`;

const CardContainer = ({
  headerTitle = "",
  headerTextAlign = "center",
  noGutters,
  children,
  bodyTextAlign = "center",
  variant = "primary",
  withHeader = true,
  statistic = false,
}) => {
  return (
    <Container>
      {withHeader && (
        <Header variant={variant} justify={headerTextAlign}>
          <HeaderTitle>{headerTitle}</HeaderTitle>
        </Header>
      )}
      <Body
        noGutters={noGutters}
        statistic={statistic}
        bodyTextAlign={bodyTextAlign}
      >
        {children}
      </Body>
    </Container>
  );
};

CardContainer.propTypes = {
  headerTitle: PropTypes.string,
  headerTextAlign: PropTypes.oneOf(["start", "center", "end"]),
  bodyTextAlign: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node,
  noGutters: PropTypes.bool,
  statistic: PropTypes.bool,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default CardContainer;
